import * as React from "react";
import { Chart } from "react-google-charts";

const ExampleChart = () => {
  return (
    <Chart
      width={'10rem'}
      height={'9rem'}
      chartType="Bar"
      loader={<div></div>}
      data={[
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350],
      ]}
      options={{
        // Material design options
        chart: {
          title: 'Sales (Weekly)',
        },
        legend: { position: 'none' },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  );
};

const ExampleChart2 = () => {
  return (
    <Chart
      width={'10rem'}
      height={'9rem'}
      chartType="Bar"
      loader={<div></div>}
      data={[
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350],
      ]}
      options={{
        // Material design options
        chart: {
          title: 'Sales (Monthly)',
        },
        legend: { position: 'none' },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  );
};

const ExampleChart3 = () => {
  return (
    <Chart
      width={'10rem'}
      height={'9rem'}
      chartType="Bar"
      loader={<div></div>}
      data={[
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350],
      ]}
      options={{
        // Material design options
        chart: {
          title: 'Sales (Quarterly)',
        },
        legend: { position: 'none' },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  );
};

const ExampleChart4 = () => {
  return (
    <Chart
      width={'10rem'}
      height={'9rem'}
      chartType="Bar"
      loader={<div></div>}
      data={[
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350],
      ]}
      options={{
        // Material design options
        chart: {
          title: 'Sales (Yearly)',
        },
        legend: { position: 'none' },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  );
};


export {
  ExampleChart,
  ExampleChart2,
  ExampleChart3,
  ExampleChart4
};