import React from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';

class Account extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      width: 0,
      height: 0
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.handleWindowWidth = this.handleWindowWidth.bind(this);
    this.handleWindowHeight = this.handleWindowHeight.bind(this);
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => console.log(user))
    .catch(err => console.log(err));
  }

  handleLogout(e) {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
    this.props.history.push('/login');
  }

  handleWindowWidth(e) {
    this.setState({width: window.innerWidth});
  }

  handleWindowHeight(e) {
    this.setState({height: window.innerHeight});
  }

  render() {
    const { width, height } = this.state;

    return (
      <div>
        <p>
          Account Page
        </p>
        <Button onClick={e => this.handleLogout(e)}>
          Log out
        </Button>
        <Button onClick={e => this.handleWindowWidth(e)}>
          Show window width
        </Button>
        <Button onClick={e => this.handleWindowHeight(e)}>
          Show window height
        </Button>

      </div>
    )
  }
}

export default Account;

const SidebarContainer = styled.div`
  min-width: 15vw;
  max-width: 20vw;
`;

const MainContainer = styled.div`
  min-width: 80vw;
`;

const Button = styled.button`

`;