import React from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { ContainerFluid } from '../../scaffold/blocks';
import SideBarNavigation from './SideBarNavigation';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName: 'Orders',
      authenticated: false,
      user: {}
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSidebarButtons = this.handleSidebarButtons.bind(this);
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "#fafafa";

    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: false});
      this.setState({ authenticated: true,  user});
    }
    catch(err) {
      this.props.history.push('/login');
    }
  }

  async handleLogout(e) {
    try {
      await Auth.signOut();
      this.props.history.push('/login');
    }
    catch(err) {
      this.props.history.push('/login');
    }
  }

  handleSidebarButtons(path, event) {
    this.props.history.push({
      pathname: path
    });
  }

  render() {
    const { pageName } = this.state;
    return (
      <ContainerFluid>
        <ContentContainer>
          <SideBarNavigation
            onSideBarButtonClick={this.handleSidebarButtons}
            onLogoutButtonClick={this.handleLogout}
            buttonActive={pageName}
          />
          <MainContainer>
            <ContentLeftContainer>
            </ContentLeftContainer>
            <ContentRightContainer>
            </ContentRightContainer>
          </MainContainer>
        </ContentContainer>   
      </ContainerFluid>
    );
  }
}

export default Orders;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
`;

const MainContainer = styled.div`
  background: #fafafa;
  left: calc(100vw - (100vw - 11rem));
  width: calc(100vw - 11.1rem);
  display: flex;
  flex-wrap: wrap;
  overlfow: hidden;
`;

const ContentLeftContainer = styled.div`
  flex: 0 0 50%;
  min-width: 10.76rem;
`;

const ContentRightContainer = styled.div`
  flex: 0 0 50%;
  min-width: 10.76rem;
  display: flex;
  flex-wrap: wrap;
`;