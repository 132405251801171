import React from 'react';
import { Auth } from 'aws-amplify';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { DARK_COLOR } from '../scaffold/customizer';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { validate_verification_code } from '../validation/validate';

class Verification extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      emailConfirmed: false,
      digits: Array(6).fill(''),
      verificationErrorMessage: '',
      verificationError: false,
      email: '',
      codeResent: false,
      codeMessage: ''
    }

    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  };

  handleFocus(index, e) {
    const digitsCopy = this.state.digits.slice();
    const ref = `Digit${index}`;
    if (e.target.value === '' || e.target.value === null){

    } else {
      this[`${ref}`].value = '';
    }
    
    digitsCopy[index] = '';
    this.setState({digits: digitsCopy});
  };



  // handles backspace
  handleKeyDown(index, event) {
    const digitsCopy = this.state.digits.slice();
    const currentInput = `Digit${index}`;
    const previousInput = `Digit${index - 1}`;

    // keyCode 8 is Backspace
    if (event.keyCode === 8) {
      if (index > 0 && index < 6) {
        this[currentInput].value = '';
        this[previousInput].focus();
      }
      if (index === 0) {
        this[currentInput].value = '';
      }
      digitsCopy[index] = '';
      this.setState({ digits: digitsCopy });
    }
  }

  handleKeyPress(index, event) {
    const digitsCopy = this.state.digits.slice();
    let currentInput = `Digit${index}`;
    let nextInput = `Digit${index + 1}`;
    const numberRegex = /[0-9]/;
    if (numberRegex.test(event.key)) {
      const userInput = `${event.key}`;
      if (index <= 4) {
        this[currentInput].value = userInput;
        this[nextInput].value = '';
        this[nextInput].focus();
        digitsCopy[index] = userInput;
        this.setState({ digits: digitsCopy});
        
      } else if (index === 5) {
        this.setState({ digits: digitsCopy});
        this[currentInput].value = userInput;
        digitsCopy[index] = userInput;
        
      }
    }
  }

  async handleSubmit(e) {
    const currentDigits = this.state.digits.slice();
    if (validate_verification_code(currentDigits, 6)) {
      const code = currentDigits.join('');
      const email = this.props.location.state.email;
      try {
        await Auth.confirmSignUp(email, code, {
          // Optional. Force user confirmation irrespective of existing alias. By default set to True.
          forceAliasCreation: true
        });
        this.setState({ emailConfirmed: true });
      } catch(err) {
        this.setState({ verificationErrorMessage: err.message, verificationError: true})
      }   
    } else {
      // Fails Test
      const message = 'Please check your code and try again.'
      this.setState({ verificationErrorMessage: message, verificationError: true});
    }
  };

  async handleResendCode(e) {
    const email = (this.props.location.state && this.props.location.state.email) !== undefined ?
      this.props.location.state.email : '';
    if (email.length > 0) {
      try {
        await Auth.resendSignUp(email);
        this.setState({ codeResent: true, codeMessage: 'Code has been resent. You may need to check your spam folder.'});
      } catch (err) {
        this.setState({ verificationErrorMessage: err.message, verificationError: true });
      }
    }
  }

  render(){
    const email = (this.props.location.state && this.props.location.state.email) !== undefined ?
      this.props.location.state.email : '';

    const { 
      emailConfirmed,
      digits,
      errorMessage,
      errorState,
      codeResent,
      codeMessage,
    } = this.state;

    if (!email || email.length === 0) {
      return (
        <Redirect to='/login'/>
      )
    }
    else if (emailConfirmed) {
      return (
        <Redirect to='/verification-success' />
      )
    }
    else {
      return (
        <AuthContainer>
          {
            console.log(digits)
          }
          {
            codeResent && (
              <ResendText>
              { codeMessage }
              </ResendText>
            )
          }
          <ErrorMessage errorState={errorState}>
            { errorMessage }
          </ErrorMessage>
          <HeaderText>
            Please enter the 6-digit Verification <br/> code sent to your email:
          </HeaderText>
            <DigitContainer>
              {
                digits.map((value, index) => {
                  if (index === 0) {
                    return (
                      <DigitInput
                      autoFocus
                      key={index}
                      ref={(node) => this[`Digit${index}`] = node}
                      placeholder='•'
                      type='text'
                      maxLength='1'
                      min='0'
                      max='9'
                      pattern='[0-9]{1}'
                      onFocus={e => this.handleFocus(index, e)}
                      onKeyDown={e => this.handleKeyDown(index, e)}
                      onKeyPress={e => this.handleKeyPress(index, e)}
                      />
                    );
                  } else {
                    return (
                      <DigitInput
                        key={index}
                        ref={(node) => this[`Digit${index}`] = node}
                        placeholder='•'
                        type='text'
                        maxLength='1'
                        min='0'
                        max='9'
                        pattern='[0-9]{1}'
                        onFocus={e => this.handleFocus(index, e)}
                        onKeyDown={e => this.handleKeyDown(index, e)}
                        onKeyPress={e => this.handleKeyPress(index, e)}
                      />
                    );
                  };
                })
              }
            </DigitContainer>
            <VerificationHelper>
              Didn't receive your code? 
              <ResendCode onClick={e => this.handleResendCode(e)}>
                Resend Code
              </ResendCode>
            </VerificationHelper>
          <ConfirmButton
            onClick={event => this.handleSubmit(event)}
          >
            Submit Code
          </ConfirmButton>
        </AuthContainer>
      );
    }
  }
}

export default withRouter(Verification);

const ErrorMessage = styled.p`
  font-size: 0.6rem;
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin: 0;
  ${({ errorState }) => errorState && `
    color: crimson;
  `}
`;

const ResendText = styled.span`
  font-size: 0.6rem;
  margin-bottom: 0.67rem;
`;

const ResendCode = styled.button`
  font-size: 0.6rem;
  border: none;
  background: white;
  padding: 0;
  margin-left: 0.1rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const DigitContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DigitInput = styled.input`
  font-size: 1rem;
  border-radius: 0.15rem;
  padding: 0.36rem;
  margin: 0.25rem;
  border: 1px solid Silver;
  width: 1.2rem;
  text-align: center;
  &:focus {
    outline: none;
    box-shadow: 0 0 0.25rem rgba(192, 192, 192, 1);
    border: 1px solid rgba(136, 136, 136, 1);
  }
  &::placeholder {
    font-size: 1.2rem;
    text-align: center;
  }
`;

const VerificationHelper = styled.span`
  margin: 0.2rem;
  padding: 0.5rem;
  font-size: 0.6rem;
`;

const ConfirmButton = styled.button`
  color: #FFF;
  background-color: ${DARK_COLOR};
  border-color: ${DARK_COLOR};
  cursor: pointer;
  border-radius: .1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0.4rem 5.5rem;
  font-size: 0.67rem;
  line-height: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important;
    filter: brightness(1.25);
  }
`;