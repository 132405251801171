import React from 'react';
import './App.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Home from './components/Home';
import About from './components/About';
import OurProducts from './components/OurProducts';
import Pricing from './components/Pricing';
import Login from './components/Login';
import CreateAccount from './components/CreateAccount';
import Verification from './components/Verification';
import VerificationSuccess from './components/VerificationSuccess';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import PasswordResetSuccess from './components/PasswordResetSuccess';
import MultiFactorSetup from './components/MultiFactorSetup';
import MultiFactorSuccess from './components/MultiFactorSuccess';
import MFAuthentication from './components/MFAuthentication';
import Account from './components/Account';

//Main App
import Dashboard from './components/MainApp/Dashboard';
import CreateShop from './components/MainApp/CreateShop';
import MyShops from './components/MainApp/MyShops';
import Products from './components/MainApp/Products';
import Orders from './components/MainApp/Orders';
import Reviews from './components/MainApp/Reviews';
import Revenue from './components/MainApp/Revenue';
import ShopStats from './components/MainApp/ShopStats';
import Advertise from './components/MainApp/Advertise';
import Billing from './components/MainApp/Billing';
import Settings from './components/MainApp/Settings';
import FAQ from './components/MainApp/FAQ';
import Support from './components/MainApp/Support';

import { I18n } from 'aws-amplify';

Amplify.configure(awsconfig);

// To change labels on the authenticator
I18n.setLanguage('en');
const dict = {
     'en': {
         'Username': 'Email Address',
         'Sign in to your account': 'Welcome back to the MegaMart',
         'Create a new account': 'Create Your Account',
         'Enter your username': 'Email Address',
         'Enter your password': 'Password',
         'Email': 'Email Address'
      }
}
I18n.putVocabularies(dict);


function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/our-products" component={OurProducts} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/verify-email" component={Verification} />
          <Route path="/verification-success" component={VerificationSuccess} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/password-reset-success" component={PasswordResetSuccess} />
          <Route path="/multifactor-setup" component={MultiFactorSetup} />
          <Route path="/multifactor-success" component={MultiFactorSuccess} />
          <Route path="/authentication" component={MFAuthentication} />

          {/* Main App */}
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/create-shop" component={CreateShop} />
          <Route path="/my-shops" component={MyShops} />
          <Route path="/products" component={Products} />
          <Route path="/orders" component={Orders} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/revenue" component={Revenue} />
          <Route path="/shop-stats" component={ShopStats} />
          <Route path="/advertise" component={Advertise} />
          <Route path="/billing" component={Billing} />
          <Route path="/settings" component={Settings} />
          <Route path="/faq" component={FAQ} />
          <Route path="/support" component={Support} />

          <Route path="/account" component={Account} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

/*
position: 'absolute',
    left: '0%',
    top: '50%',
    h1: {
      color: '#FFF',
      'padding-left': '5vw'
    }

*/