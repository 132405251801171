
import React from 'react';
import { Link } from 'react-router-dom'
import { NavigationJustifySpaceBetween } from '../scaffold/containers';
import { NavLinks } from '../scaffold/navigation';
import Logo from './Logo';


function FrontNavBar() {
  return(
    <NavigationJustifySpaceBetween>
      <div>
        <Logo url={'/'}/>
      </div>
      <NavLinks>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/products">Products</Link>
        </li>
        <li>
          <Link to="/pricing">Pricing</Link>
        </li>
        <li>
          <Link to="/login">Log In</Link>
        </li>
        <li>
          <Link to="/create-account">Create Account</Link>
        </li>
      </NavLinks>
    </NavigationJustifySpaceBetween>
  );
}

export default FrontNavBar;