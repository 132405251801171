import styled from 'styled-components';

import {
  DEFAULT_COLOR,
  SECONDARY_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
  ALERT_COLOR,
  LIGHT_COLOR,
  DARK_COLOR,
  BLUE_COLOR,
} from './customizer';

export const ButtonDefault = styled.button({
  'color': '#FFF',
  'background-color': DEFAULT_COLOR,
  'border-color': DEFAULT_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(0.95)'
  },
});

export const ButtonSecondary = styled.button({
  'color': '#FFF',
  'background-color': SECONDARY_COLOR,
  'border-color': SECONDARY_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(0.95)'
  },
});

export const ButtonSuccess = styled.button({
  'color': '#FFF',
  'background-color': SUCCESS_COLOR,
  'border-color': SUCCESS_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(0.95)'
  },
});

export const ButtonWarning = styled.button({
  'color': '#FFF',
  'background-color': WARNING_COLOR,
  'border-color': WARNING_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(0.95)'
  },
});

export const ButtonAlert = styled.button({
  'color': '#FFF',
  'background-color': ALERT_COLOR,
  'border-color': ALERT_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(0.95)'
  },
});

export const ButtonLight = styled.button({
  'color': '#2d3436',
  'background-color': LIGHT_COLOR,
  'border-color': '#2d3436',
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #FFFFFF, 0 0 0 5px Gainsboro, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(95%)'
  },
});

export const ButtonDark = styled.button({
  'color': '#FFF',
  'background-color': DARK_COLOR,
  'border-color': DARK_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(1.25)'
  },
});

export const ButtonBlue = styled.button({
  'color': '#FFF',
  'background-color': BLUE_COLOR,
  'border-color': BLUE_COLOR,
  'border-radius': '.25rem',
  'font-weight': '600',
  'text-align': 'center',
  'white-space': 'nowrap',
  'border': '1px solid transparent',
  'padding': '0.4rem 1rem',
  'font-size': '0.67rem',
  'line-height': '1rem',
  'cursor': 'pointer',
  'transition': 'color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  '&:focus': {
    'outline': 0,
    'box-shadow': '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important',
    'filter': 'brightness(1.25)'
  },
  '&:hover': {
    'filter': 'brightness(1.15)'
  }
});