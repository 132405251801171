import styled from 'styled-components';

const HeaderText = styled.h5`
  margin: 0rem 0.5rem 0.5rem 0.5rem;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21rem;
  margin: auto;
  margin-top: 12vh;
`;

export {
  HeaderText,
  AuthContainer
}
