import styled from 'styled-components';

const SuccessText = styled.h5`
  margin: 0.5rem 0;
`;

const MainText = styled.p`
  font-size: 0.67rem;
  margin: 0.25rem 0;
  padding:0;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21rem;
  margin: auto;
  margin-top: 25vh;
`;

const GoToLoginButton = styled.button`
  font-size: 0.67rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export {
  SuccessText,
  MainText,
  SuccessContainer,
  GoToLoginButton
}