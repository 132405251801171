import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTools,
  faLandmark,
  faStore,
  faCubes,
  faTruck,
  faMedal,
  faDollarSign,
  faChartArea,
  faAd,
  faCogs,
  faQuestion,
  faComments,
  faFileInvoiceDollar,
  faDoorClosed
} from '@fortawesome/free-solid-svg-icons';

class SideBarNavigation extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      sideBarButtons: [
        { name: 'Dashboard', path: '/dashboard', icon: faLandmark, index: 0 },
        { name: 'Create Shop', path: '/create-shop', icon: faTools, index: 1 },
        { name: 'My Shops', path: '/my-shops', icon: faStore, index: 2 },
        { name: 'Products', path: '/products', icon: faCubes, index: 3 },
        { name: 'Orders', path: '/orders', icon: faTruck, index: 4 },
        { name: 'Reviews', path: '/reviews', icon: faMedal, index: 5 },
        { name: 'Revenue', path: '/revenue', icon: faDollarSign, index: 6 },
        { name: 'Shop Stats', path: '/shop-stats', icon: faChartArea, index: 7 },
        { name: 'Advertise', path: '/advertise', icon: faAd, index: 8 },
        { name: 'Billing', path: '/billing', icon: faFileInvoiceDollar, index: 9 },
        { name: 'Settings', path: '/settings', icon: faCogs, index: 10 },
        { name: 'FAQ', path: '/faq', icon: faQuestion, index: 11 },
        { name: 'Support', path: '/support', icon: faComments, index: 12 }
      ],
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSideBarButtons = this.handleSideBarButtons.bind(this);
  }

  handleSideBarButtons(index, e) {
    let { path } = this.state.sideBarButtons[index];
    this.props.onSideBarButtonClick(path, e);
  }

  handleLogout(e) {
    this.props.onLogoutButtonClick(e);
  }

  render() {
    
    const { buttonActive } = this.props;
    const sideBarState = this.state.sideBarButtons.slice();
    const [currentPageButton] = sideBarState.filter(el => el.name === buttonActive);

      return (
      <SidebarContainer>
      <SideBarSpacer space={'0.2rem'}></SideBarSpacer>
      <MinimizeLogoButtonContainer>
      <HamburgerButton>
        <FontAwesomeIcon icon={faBars} fixedWidth color="#404040"/>
      </HamburgerButton>
      <ButtonSpacer />
      <HomeButton>
        MegaMart
      </HomeButton>
      </MinimizeLogoButtonContainer>
      <SideBarSpacer space={'0.2rem'}></SideBarSpacer>
      {
        this.state.sideBarButtons.map(el => {
          if (currentPageButton.index === el.index) {
            return (
              <SideBarButton
                key={el.index}
                active={true}
                index={el.index}
                onClick={e => this.handleSideBarButtons(el.index, e)}
                >
                <FontAwesomeIcon icon={el.icon} fixedWidth/>
                <ButtonSpacer />
                {el.name}
              </SideBarButton>
            )
          } else {
            return (
              <SideBarButton
                key={el.index}
                active={false}
                index={el.index}
                onClick={e => this.handleSideBarButtons(el.index, e)}
                >
                <FontAwesomeIcon icon={el.icon} fixedWidth/>
                <ButtonSpacer />
                {el.name}
              </SideBarButton>
            );
          }
        })
      }
      <SideBarButton onClick={e => this.handleLogout(e)}>
        <FontAwesomeIcon icon={faDoorClosed} fixedWidth/>
        <ButtonSpacer />
        Log Out
      </SideBarButton>
      </SidebarContainer>
    );
  }
}

export default SideBarNavigation;

const HomeButton = styled.button`
  color: #404040;
  height: 2rem;
  font-size: 0.76rem;
  border: none;
  cursor: pointer;
  padding-left: 0;
  margin-left: 0;
  font-weight: 600;
  letter-spacing: -1px;
  &:focus {
    outline: none;
  }
`;

const HamburgerButton = styled.button`
  color: #404040;
  height: 1.75rem;
  font-size: 0.76rem;
  font-weight: 600;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  &:hover {
    
  }
  &:focus {
    outline: none;
  }
`;

const ButtonSpacer = styled.span`
  padding-left: 1.25vw;
`;

const SidebarContainer = styled.div`
  min-width: 11rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: White;
`;

const SideBarButton = styled.button`
  color: #404040;
  height: 1.75rem;
  font-size: 0.72rem;
  font-weight: 500;
  background: none;
  cursor: pointer;
  padding-left: 2vw;
  text-align: left;
  border: none;
  background-color: rgb(254,254,254);
  filter: brightness(102%);
  ${({ active }) => active && `
    background-color: rgb(230,230,230);
  `};
  &:hover {
    filter: brightness(97%);
  }
  &:focus {
    outline: none;
  }
`;

const SideBarSpacer = styled.div`
  height: ${props => props.space};
`;

const MinimizeLogoButtonContainer = styled.div`
  height: 2rem;
  padding-left: 2vw;
  padding-bottom: 0.2rem;
  text-align: left;
  border-bottom: 3px solid WhiteSmoke;
`;