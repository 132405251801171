import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { DARK_COLOR } from '../scaffold/customizer';
import Logo from './Logo';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import MFAuthentication from './MFAuthentication';
import Account from './Account';

class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inputFields: [
        { name: 'emailAddress', type:'text', placeholder: 'Email Address', input: '', active: false, index: 0, justTyped: false, hasError: false, errorMessage:'', firstInput: true},
        { name: 'password', type:'password', placeholder: 'Password', input: '', active: false, index: 1, justTyped: false, hasError: false, errorMessage:'', firstInput: false }
      ],
      passesValidation: false,
      loginButtonEnabled: false,
      loginError: false,
      requestingAuth: false,
      buttonAnimation: false,
      helpLoggingIn: false,

      // State for Rendering the specific page
      
      sendToCreateAccount: false,
      sendToVerification: false,
      sendToForgotPassword: false,
      // This is the actual auth page, rest are for setup
      sendToMFAuthentication: false,
      // Used for MFA Setup
      sendToMFASetup: false,

      //User Object
      user: {},
      username: '',

      // MFA Setup Code -> required for MFA
      showButton: false,

      MFACode: '',

      // MFA Verification fields
      MFAVerificationError: false,
      MFAVerificationErrorMessage: '',
      MFAVerificationInput: '',
      MFAVerificationButtonLoading: false,

      // MFA Authentiction
      sendToMFAAuthentication: false,
      sendToNewPassword: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputActive = this.handleInputActive.bind(this);
    this.handleInputInactive = this.handleInputInactive.bind(this);
    this.handleButtonAnimation = this.handleButtonAnimation.bind(this);
    this.handleAccountHelper = this.handleAccountHelper.bind(this);
    this.handleCreateAccountButton = this.handleCreateAccountButton.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => console.log(user))
    .catch(err => console.log(err));
  }

  // Handle Login Page

  async handleSubmit(e){
    this.setState({ requestingAuth: true });
    const currentInputFields = this.state.inputFields.slice();

    const username = currentInputFields[0].input;
    const password = currentInputFields[1].input;
    
    try {
      const user = await Auth.signIn(username, password);
      
      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.setState({ requestingAuth: false });

        this.props.history.push({
          pathname: '/authentication',
          state: {
            username,
            password
          }
        });
      }
      else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

      }
      else if (user.challengeName === 'MFA_SETUP') {
        this.setState({ requestingAuth: false });
        // Make sure when we leave the page this turns to false to turn off highlight
        currentInputFields[1].active = false;
        
        this.props.history.push({
          pathname: '/multifactor-setup',
          state: {
            username,
            password
          }
        });
      } else {
        // User can only sign in via software token mfa, leave them on page.
        this.setState({ requestingAuth: false });
      }
    }
    catch(err) {
      this.setState({ requestingAuth: false });
      const newInputFields = this.state.inputFields.slice();
      const emailObject = newInputFields[0];
      console.log(err);
      console.log(err.message);
      
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
        let errorMessage = 'Incorrect email address or password used.'
        emailObject.hasError = true;
        emailObject.errorMessage = errorMessage;
        this.setState({ inputFields: newInputFields, loginError: true });
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        let errorMessage = 'Incorrect email address or password used.'
        emailObject.hasError = true;
        emailObject.errorMessage = errorMessage;
        this.setState({ inputFields: newInputFields, loginError: true });
      } else {
        console.log(err);
      }
    }
  }

  // Login Page Handlers - Login has several components to handle due to lifted state

  handleChange(index, userInput){
    let loginButtonEnabled = false;
    let newInputFieldsState = this.state.inputFields.slice();
    newInputFieldsState[index].input = userInput;
    newInputFieldsState[0].hasError = false;
    newInputFieldsState[0].errorMessage = '';

    // If both fields are active then enable button
    if (newInputFieldsState[0].input.length > 0 && newInputFieldsState[1].input.length > 0) {
      loginButtonEnabled = true;
    } else {
      loginButtonEnabled = false;
    }
    this.setState({ newInputFieldsState, loginButtonEnabled, loginErrorMessage: '', loginError: false, });
  }

  handleInputActive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    newInputFieldsState[index].active = isActive;
    this.setState({ inputFields: newInputFieldsState });
  }

  handleInputInactive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    // Used to change the background color (when state is not active for the input field)
    newInputFieldsState[index].active = isActive;
    this.setState({ inputFields: newInputFieldsState });
  }

  handleKeyPress(index, event) {
    if (event.key === 'Enter' && this.state.loginButtonEnabled === true) {
      this.handleSubmit();
    }
  }

  handleButtonAnimation(event) {
    if (event.type === 'mousedown') {
      this.setState({ buttonAnimation: true });
    } else {
      this.setState({ buttonAnimation: false });
    }
  }

  handleAccountHelper(event) {
    this.setState({ helpLoggingIn: true })
  }

  handleCreateAccountButton(event) {
    this.setState({ sendToCreateAccount: true });
  }

  // MFA Setup Handlers
  handleShowButtonClick(e) {
    this.props.onShowButtonClick(e);
  }

  handleCancelButtonClick(e) {
    this.props.onCancelButtonClick(e);
  }

  handleNextButtonClick(e) {
    this.props.onNextButtonClick(e);
  }

  // MFA Verification Handlers

  render(){
    // Login States / Global
    const {
      loginButtonEnabled, 
      helpLoggingIn, 
      sendToCreateAccount,
      sendToMFAuthentication,
      sendToAccountPage,
      user,
      requestingAuth
    } = this.state;

    // Send to Forgot Password Page

    if (helpLoggingIn === true) {
      return (
        <Redirect to='/forgot-password' />
      );
    } 

    // Send to Create Account Page

    else if (sendToCreateAccount) {
      return (
        <Redirect to='/create-account' />
      );
    }
    // Send to MF Authentication Page for Login
    else if (sendToMFAuthentication) {
      return (
        <MFAuthentication>
        </MFAuthentication>
      );
    }
    // Successfully logs in!
    else if (sendToAccountPage && user) {
      return (
        <Account></Account>
      )
    }
    // The Main Login Page
    else {
      return (
        <AuthContainer>
          <Logo url={'/'}/>
          <HeaderText>
            MegaMart
          </HeaderText>
          <FormContainer>
            {
              this.state.inputFields.map(el => (
                <FieldRow key={el.index}>
                  <InputContainer key={el.index} active={el.active} index={el.index}>
                    <Input
                    key={el.index}
                    ref={(node) => this[`InputField${el.index}`] = node}
                    autoFocus={el.firstInput}
                    type={el.type}
                    index={el.index}
                    active={el.active}
                    placeholder={el.placeholder}
                    onChange={e => this.handleChange(el.index, e.target.value)}
                    onFocus={() => this.handleInputActive(el.index, true)}
                    onBlur={() => this.handleInputInactive(el.index, false)}
                    onKeyPress={(e) => this.handleKeyPress(el.index, e)}
                    />
                    <ErrorMessage hasError={el.hasError}>
                      {el.errorMessage}
                    </ErrorMessage>
                  </InputContainer> 
                </FieldRow>
              ))
            }
          </FormContainer>
          <LoginButton
            disabled={!loginButtonEnabled}
            onClick={e => this.handleSubmit(e)}
            onMouseUp={e => this.handleButtonAnimation(e)}
            onMouseDown={e => this.handleButtonAnimation(e)}
            >
            {
              !requestingAuth && `Log In`
            }
            {
              requestingAuth && (
                <FontAwesomeIcon icon={faCircleNotch} spin/>
              )
            }
          </LoginButton>
          <AccountHelperText onClick={e => this.handleAccountHelper(e)}>
            Forgot Password? Having an issue with logging in?
          </AccountHelperText>
          <OrSeperator>
            <span>or</span>
          </OrSeperator>
          <CreateAccountText>
            Don't have an account?&nbsp;
            <CreateAccountButton onClick={e => this.handleCreateAccountButton(e)}>
              Create Account
            </CreateAccountButton>
          </CreateAccountText>
        </AuthContainer>
      );
    }
    
  }
}

export default Login;

const AccountHelperText = styled.button`
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  font-size: 0.6rem;
  border: none;
  background: white;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const FieldRow = styled.div`
  min-width: 19rem;
  max-width: 100%;
`;

const FormContainer = styled.div`
  padding: 0;
  margin-top: 0.5rem;
`;

const InputContainer = styled.div`
  padding-left: 0.08rem;
  padding-right: 0.08rem;
  ${({ active }) => active && `
  background: WhiteSmoke;
  `}
  border-bottom-style: solid;
  border-bottom-color: WhiteSmoke;
  border-bottom-width: 2px;
  width: 16.5rem;
  display: inline-block;
`;

const Input = styled.input`
  font-size: 0.67rem;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding: 0.5rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  width: 15.5rem;
`;

const LoginButton = styled.button`
  margin-top: 1rem;
  color: #FFF;
  background-color: ${DARK_COLOR};
  border-color: ${DARK_COLOR};
  cursor: pointer;
  ${({ disabled }) => disabled && `
    background-color: LightGray;
    cursor: not-allowed;
  `};
  border-radius: .1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0.4rem 1rem;
  width: 16.5rem;
  font-size: 0.67rem;
  line-height: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus {
      outline: 0;
    }
`;

const ErrorMessage = styled.p`
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin: 0;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;

const OrSeperator = styled.p`
  width: 75%; 
  text-align: center; 
  border-bottom: 1px solid Gainsboro;
  line-height: 0.1em;
  margin: 0.3rem 0.7rem; 
  span {
    color: Gainsboro;
    font-size: 0.55rem;
    background:#fff; 
    padding:0 10px; 
  }
`;

const CreateAccountText = styled.p`
  font-size: 0.6rem;
  padding: 0;
  margin: 0.67rem;
  color: Gray;
`;

const CreateAccountButton = styled.button`
  font-size: 0.6rem;
  border: none;
  background: white;
  padding: 0;
  margin-left: 0.1rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;