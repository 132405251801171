import validator from 'validator';

const hasLowerCase = (str) => {
  return (/[a-z]/.test(str));
}

const hasUpperCase = (str) => {
  return (/[A-Z]/.test(str));
}

const hasNumber = (str) => {
  return (/[0-9]/.test(str));
}

const hasWhiteSpace = (str) => (/\s/g.test(str));

const isValidName = (str) => (/^[a-z ,.'-]+$/i.test(str));

const validate_verification_code = (array, length) => {
    const possibleValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    const currentDigits = array;
    const actualDigits = currentDigits.filter((e) => possibleValues.includes(e));
    if (actualDigits.length === length) {
      return true
    } else {
      return false
    }
}

const validate_name = (str) => {
  if (typeof str !== 'string'){
    return {
      pass: false,
      error: 'Password must be a string'
    }
  } else if (str.length > 128) {
    return {
      pass: false,
      error: 'Please shorten the name submitted.'
    }
  } else if (str.length < 1) {
    return {
      pass: false,
      error: 'Check your information and try again.'
    }
  } else if (isValidName(str)){
    return {
      pass: true,
      error: null
    }
  } else {
    return {
      pass: false,
      error: 'Check your information and try again.'
    }
  }
}

const validate_password = (str) => {
  if (typeof str !== 'string'){
    return {
      pass: false,
      error: 'Password must be a string'
    }
  } else if (str.length < 8) {
    return {
      pass: false,
      error: 'Password must have at least 8 characters, one uppercase, one lowercase, and one number.'
    }
  } else if (hasLowerCase(str) === false) {
    return {
      pass: false,
      error: 'Password must have at least 8 characters, one uppercase, one lowercase, and one number.'
    }
  } else if (hasUpperCase(str) === false) {
    return {
      pass: false,
      error: 'Password must have at least 8 characters, one uppercase, one lowercase, and one number.'
    }
  } else if (hasNumber(str) === false) {
    return {
      pass: false,
      error: 'Password must have at least 8 characters, one uppercase, one lowercase, and one number.'
    }
  } else if (str.length > 128) {
    return {
      pass: false,
      error: 'Please shorten your password.'
    }
  } else if (hasWhiteSpace(str) === true) {
    return {
      pass: false,
      error: 'Please remove any spaces from your password.'
    }
  } else {
    return {
      pass: true,
      error: null
    }
  }
}

const validate_email = (str) => {
  if (typeof str !== 'string') {
    return {
      pass: false,
      error: 'Email must be a string'
    }
  } else if (str.length < 2) {
    return {
      pass: false,
      error: 'Check your information and try again.'
    }
  } else if (validator.isEmail(str)) {
    return {
      pass: true,
      error: null
    }
  } else {
    return {
      pass: false,
      error: 'Please enter a valid email address.'
    }
  }
}

export {
  validate_name,
  validate_password,
  validate_email,
  validate_verification_code
}