/* Buttons */
export const DEFAULT_COLOR = '#007bff';
export const SECONDARY_COLOR = '#ff5a5f';
export const SUCCESS_COLOR = '#5cb85c';
export const WARNING_COLOR = '#f0ad4e';
export const ALERT_COLOR = '#d9534f';
export const LIGHT_COLOR = '#FFF';
export const DARK_COLOR = '#212121';
export const BLUE_COLOR = 'rgb(22, 82, 240)';
export const SILVER_COLOR = 'rgb(192,192,192)';

/* Navigation Bar */

export const NAVLINKCOLOR = '#212121';
/* Must be in units of rem and greater than 2.05 (the default) */
export const NAVBARHEIGHT = '2.1rem';