import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

import Logo from './Logo';

class MFAuthentication extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      active: true,
      authenticationInput: '',
      errorStatus: false,
      errorMessage: '',
      buttonLoading: false,
      buttonDisabled: false,
      mfaType: 'SOFTWARE_TOKEN_MFA'
    }
    this.handleInputActive = this.handleInputActive.bind(this);
    this.handleInputInactive = this.handleInputInactive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleGoToLoginButton = this.handleGoToLoginButton.bind(this);
  }

  async handleSubmit(e) {
    this.setState({ buttonLoading: true, buttonDisabled: true });
  
    const { 
      username,
      password
    }
    = this.props.location.state;

    const { 
      authenticationInput,
      mfaType
    } = this.state;

    const code = authenticationInput;

    try {
      // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
      // Use the generated one-time password to verify the setup
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        const loggedUser = await Auth.confirmSignIn(
          user,
          code,
          mfaType
        );
        this.props.history.push('/dashboard');
      } else {
        this.setState({ buttonLoading: false, buttonDisabled: false });
        this.props.history.push('/login');
      }
    } catch(err) { 
      const errorMessage = 'Please check your code and try again.';
      this.setState({ 
        buttonLoading: false,
        errorStatus: true,
        errorMessage,
        sendToMFAVerificationSuccess: false,
        buttonDisabled: false
      });
      // Token is not verified
    }
  }

  handleChange(event) {
    const userInput = event.target.value;
    this.setState({ authenticationInput: userInput });
  }

  handleInputActive(isActive) {
    this.setState({ active: isActive });
  }

  handleInputInactive(isActive) {
    this.setState({ active: isActive });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleGoToLoginButton(e) {
    this.props.history.push('/login');
  }

  render(){
    const locationState = (typeof this.props.location.state === 'undefined') ? {} : this.props.location.state;
    if (Object.keys(locationState).length === 0 && locationState.constructor === Object) {
      return (
        <Redirect to='/login' />
      );
    }
    else {
      const {
        errorStatus,
        errorMessage,
        buttonLoading,
        active,
        buttonDisabled
      } = this.state;

      return (
        <AuthContainer>
          <Logo />
          <HeaderText>
            Verify Authenticator
          </HeaderText>
          <ErrorMessage hasError={errorStatus}>
            {errorMessage}
          </ErrorMessage>
          <HeaderMessage>
            Enter the one-time password generated from your authentication app.
          </HeaderMessage>
          <FieldRow>
            <FontAwesomeIcon icon={faMobileAlt} size='2x'/>
            <Input
              autoFocus
              ref={(node) => this['InputField0'] = node}
              active={active}
              onFocus={() => this.handleInputActive(true)}
              onBlur={() => this.handleInputInactive(false)}
              onChange={(e) => this.handleChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              maxLength='7'
            >
            </Input>
            <VerifyButton 
            onClick={e => this.handleSubmit(e)}
            disabled={buttonDisabled}
            >
            {
                !buttonLoading && `Verify`
              }
              {
                buttonLoading && (
                  <FontAwesomeIcon icon={faCircleNotch} spin/>
                )
              }
            </VerifyButton>
          </FieldRow>
          <FooterMessage>
            If you are having issues with your one time code, please contact support.
          </FooterMessage>
          <OrSeperator>
            <span>or</span>
          </OrSeperator>
          <GoToLoginButton onClick={e => this.handleGoToLoginButton(e)}>Go Back</GoToLoginButton> 
        </AuthContainer>
      );
    }
  }
}

export default MFAuthentication;

const HeaderMessage = styled.p`
  font-size: 0.72rem;
  text-align: center;
  padding: 0 3rem;
`;

const GoToLoginButton = styled.button`
  font-size: 0.67rem;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0.67rem;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;


const FooterMessage = styled.p`
  font-size: 0.67rem;
  text-align: center;
  padding: 0 2.5rem;
`;

const FieldRow = styled.div`
  min-width: 19rem;
  max-width: 100%;
  margin: 0.67rem 0;
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  margin: 0 0.25rem 0.25rem 0.35rem;
  padding-left: 0.5rem;
  font-size: 0.67rem;
  border: 1px solid Gainsboro;
  letter-spacing: 0.2rem;
  border-radius: 0.1rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  height: 1.79rem;
  width: 4.5rem;
`;

const ErrorMessage = styled.p`
  color: crimson;
  text-align: center;
  font-size: 0.6rem;
  margin: 0;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;

const VerifyButton = styled.button`
  font-size: 0.6rem;
  font-weight: 600;
  background: rgb(48,48,48);
  color: #FFF;
  width: 4.25rem;
  height: 1.95rem;
  cursor: pointer;
  border-radius: 0.1rem;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #FFF;
    border-color: Gainsboro;
    background: rgb(56,56,56);
  }
`;

const OrSeperator = styled.p`
  width: 65%; 
  text-align: center; 
  border-bottom: 1px solid Gainsboro;
  line-height: 0.1em;
  margin: 0.3rem 0.7rem; 
  span {
    color: Gainsboro;
    font-size: 0.55rem;
    background:#fff; 
    padding:0 10px; 
  }
`;