import React from 'react';
import { ContainerFluid } from '../scaffold/blocks'
import FrontNavBar from './FrontNavBar';

function OurProducts() {
  return (
  <ContainerFluid>
    <FrontNavBar> 
    </FrontNavBar>
    <h2>Products</h2>
  </ContainerFluid>
  );
}

export default OurProducts;