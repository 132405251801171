import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { Auth } from 'aws-amplify';
import { withRouter, Redirect } from 'react-router-dom';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import Logo from './Logo';
import MultiFactorVerification from './MultiFactorVerification';

class MultiFactorSetup extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      showCode: false,
      goToVerification: false,
      goToLogin: false,
      issuer: 'MegaMart',
      user: {},
      username: '',
      code: '',
      // Multifactor verification states
      MFAVerificationError: false,
      MFAVerificationErrorMessage: '',
      MFAVerificationInput: '',
      MFAVerificationButtonLoading: false,
      sendToMFAVerificationSuccess: false
    }
    this.handleShowButtonClick = this.handleShowButtonClick.bind(this);
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handleVerifyClick = this.handleVerifyClick.bind(this);
    this.handleMFAVerificationOnChange = this.handleMFAVerificationOnChange.bind(this);
    this.handleGoBackButtonClick = this.handleGoBackButtonClick.bind(this);
  }

  async componentDidMount(){
    const locationState = (typeof this.props.location.state === 'undefined') ? {} : this.props.location.state;

    if (Object.keys(locationState).length > 0 && locationState.constructor === Object) {
      const { username, password } = this.props.location.state;
      try {
        const user = await Auth.signIn(username, password);
        if (user.challengeName === 'MFA_SETUP') {
          const code = await Auth.setupTOTP(user);
          this.setState({
            user,
            code,
            username,
            loading: false
          });
        } else {
          throw new Error({'message':'MFA already setup for the user.'});
        }
      } catch (err) {
        this.props.history.push('/login');
      }    
    }
    else {
      this.props.history.push('/login');
    }
  }

  // Handlers for Initial Page

  handleShowButtonClick(e) {
    this.setState({ showCode: true });
  }

  handleNextButtonClick(e) {
    this.setState({ goToVerification: true });
  }

  // Handlers for Verification Page

  handleGoBackButtonClick(e) {
    this.setState({ goToVerification: false });
  }

  async handleVerifyClick(event) {
    this.setState({ MFAVerificationButtonLoading: true });
  
    const { 
      user,
      MFAVerificationInput
    }
    = this.state;

    const code = MFAVerificationInput;

    try {
      // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
      // Use the generated one-time password to verify the setup
      await Auth.verifyTotpToken(user, code);
      // don't forget to set TOTP as the preferred MFA method
      await Auth.setPreferredMFA(user, 'TOTP');
      this.setState({
        MFAVerificationButtonLoading: false,
        sendToMFAVerificationSuccess: true,
        goToVerification: false
      });
    } catch(err) {
      console.log(err);
      console.log(err.message);  
      const errorMessage = 'Please check your code and try again.';
      this.setState({ 
        MFAVerificationButtonLoading: false,
        MFAVerificationError: true,
        MFAVerificationErrorMessage: errorMessage,
        sendToMFAVerificationSuccess: false
      });
      // Token is not verified
    }
  }

  handleMFAVerificationOnChange(event) {
    const userInput = event.target.value;
    this.setState({ MFAVerificationInput: userInput });
  }

  render(){

    const { 
      goToVerification,
      MFAVerificationError,
      MFAVerificationErrorMessage,
      MFAVerificationButtonLoading,
      goToLogin,
      sendToMFAVerificationSuccess,
      loading
     } = this.state;

    // Location State doesn't exist in component -> redirect to login
    if (goToLogin) {
      return (
        <Redirect to='/login' />
      );
    }
    else if (goToVerification) {
      return (
        <MultiFactorVerification
          errorStatus={MFAVerificationError}
          errorMessage={MFAVerificationErrorMessage}
          buttonLoading={MFAVerificationButtonLoading}
          onChange={this.handleMFAVerificationOnChange}
          onSubmitClick={this.handleVerifyClick}
          onGoBackClick={this.handleGoBackButtonClick}
        />
      );
    }
    else if (sendToMFAVerificationSuccess) {
      return (
        <Redirect to='/multifactor-success'/>
      );
    }
    else if (loading) {
      return (
        <div></div>
      )
    }
    else {
      const {
        issuer,
        code,
        username,
        showCode
      } = this.state;

      const QRValuePath = `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${issuer}`;

      return (
        
        <AuthContainer>
          <Logo />
          <HeaderText>
            Set up 2-Factor Authentication
          </HeaderText>
          <UserStepsContainer>
            <SetupInstructions>
              <li>
                <b>Protecting your business is crucial.</b>
              </li>
              <li>
                Get the Google Authenticator App from your App Store available on iOS or Android.
              </li>
              <li>
                Inside the App, select setup account.
              </li>
              <li>
                Choose 'Scan Barcode'
              </li>
              <li>
                Please do not refresh this page or you will need to rescan the new barcode.
              </li>
            </SetupInstructions>
          </UserStepsContainer>
          <QRCode value={QRValuePath} />
          <ShowCode onClick={e => this.handleShowButtonClick(e)}>
            Can't scan the barcode?
          </ShowCode>
          {
            showCode && (
            <CodeText>
              Refresh the page to generate a new code. If you're still having issues, please contact support.
            </CodeText>
            )
          }
          <NextButton onClick={e => this.handleNextButtonClick(e)}>
                Next
          </NextButton>
        </AuthContainer>
      );
    }
  }
}

export default withRouter(MultiFactorSetup);

const SetupInstructions = styled.ul`
  font-size: 0.72rem;
  text-align: left;
  padding: 0 3.25rem;
`;

const ShowCode = styled.button`
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
  font-size: 0.6rem;
  border: none;
  background: white;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const CodeText = styled.p`
  font-size: 0.67rem;
  margin: 0.5rem 2rem;
`;

const UserStepsContainer = styled.div`
  padding: 0;
  margin: 0 0 0.67rem 0;
`;

const NextButton = styled.button`
  margin: 0.67rem 0;
  font-size: 0.6rem;
  font-weight: 600;
  background: rgb(48,48,48);
  color: #FFF;
  padding: 0.5rem;
  width: 16.5rem;
  cursor: pointer;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #FFF;
    border-color: Gainsboro;
    background: rgb(56,56,56);
  }
`;