import { validate_password } from './validate';

const validationTest = (object) => {
  let initialValidation = false;
  let optionalButtonState = false;
  const initialValidationTest = object.map((ele) => {
    const inputName = ele.name;
    if (inputName === 'confirmationCode'){
      if (ele.input.length < 1) {
        return false;
      } else {
        return true;
      }
    } else if (inputName === 'password'){
      const checkPasswordField = validate_password(ele.input);
      return checkPasswordField.pass;
    } else if (inputName === 'confirmPassword') {
      const checkConfirmField = validate_password(ele.input);
      const currentPassword = object.filter((ele) => ele.name === 'password')[0].input;
      if (ele.input === currentPassword && checkConfirmField.pass === true){
        return true
      } else {
        return false
      }
    } 
    return true
  });
  if (initialValidationTest.includes(false) === false){
    initialValidation = true;
    optionalButtonState = true;
  } else {
    initialValidation = false;
    optionalButtonState = false;
  }
  return {
    passesTests: initialValidation,
    optionalButtonState: optionalButtonState
  }
}

const validateField = (index, inputFieldArray)  => {
  const inputFieldArrayCopy = inputFieldArray.slice();
  const fieldObject = inputFieldArray[index];
  const userInput = fieldObject.input;
  const fieldName = fieldObject.name;
  const [passwordObject] = inputFieldArrayCopy.filter(e => e.name === 'password');

  if (fieldName === 'confirmationCode') {
    if (userInput.length < 1) {
      return ({
        pass: false,
        error: 'Verification code is required for password reset.'
      });
    } else {
      return ({
        pass: true,
        error: ''
      });
    }
  }
  else if (fieldName === 'password') {
    return validate_password(userInput);
  }
  else if (fieldName === 'confirmPassword') {
    if (userInput !== passwordObject.input) {
      return ({
        pass: false,
        error: 'Passwords do not appear to match.'
      });
    } else {
      return validate_password(userInput);
    }
  }
  else {
    return ({
      pass: false,
      error: 'Invalid Field'
    });
  }
}

export {
  validateField,
  validationTest
}