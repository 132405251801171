import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { 
  SuccessText,
  MainText,
  SuccessContainer,
  GoToLoginButton
} from '../scaffold/success';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

class VerificationSuccess extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      finishedCountdown: false,
      goToLogin: false
    }
    this.handleGoToLoginButton = this.handleGoToLoginButton.bind(this);
  }

  componentDidMount(){
    this.setTimer = setInterval(
      () => this.handleCountdown(),
      3500
    );
  }

  componentWillUnmount(){
    clearInterval(this.setTimer);
  }
  
  handleCountdown(){
    this.setState({finishedCountdown: true})
  }

  handleGoToLoginButton(e) {
    this.setState({ goToLogin: true })
  }

  render(){
    const { finishedCountdown, goToLogin } = this.state;
    if (finishedCountdown || goToLogin) {
      return (
        <Redirect to='/login' />
      );
    } else {
      return (
        <SuccessContainer>
          <FontAwesomeIcon icon={faEnvelopeOpenText} size={'2x'} color="DodgerBlue"/>
          <SuccessText>
            Email Verified
          </SuccessText>
          <MainText>
            Your email has been verified. Redirecting to log in...
            <br /> Or&nbsp;
            <GoToLoginButton onClick={e => this.handleGoToLoginButton(e)}>click here</GoToLoginButton> 
            &nbsp;if you are not redirected.
          </MainText>
        </SuccessContainer>
      );
    }
  }
}

export default withRouter(VerificationSuccess);