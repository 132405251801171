import React from 'react';
import styled from 'styled-components';
import { withRouter, Redirect } from 'react-router-dom';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { DARK_COLOR } from '../scaffold/customizer';
import Logo from './Logo';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { validateField, validationTest } from '../validation/resetPassword';

class ResetPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inputFields: [
        { name: 'confirmationCode', type:'text', placeholder: 'Confirmation Code', input: '', active: false, index: 0, justTyped: false, hasError: false, errorMessage:'', firstInput: true},
        { name: 'password', type:'password', placeholder: 'New Password', input: '', active: false, index: 1, justTyped: false, hasError: false, errorMessage:'', firstInput: false },
        { name: 'confirmPassword', type:'password', placeholder: 'Confirm New Password', input: '', active: false, index: 2, justTyped: false, hasError: false, errorMessage:'', firstInput: false }
      ],
      passesValidation: false,
      resetButtonEnabled: false,
      resetError: false,
      resetErrorMessage: '',
      requestingAuth: false,
      buttonAnimation: false,
      resetSuccess: false,
      sendToForgotPassword: false,
      email: ''
    }
  }

  componentDidMount() {
    this['InputField0'].focus();
  }

  async handleSubmit(e){
    this.setState({ requestingAuth: true, resetButtonEnabled: false });
    const currentInputFields = this.state.inputFields.slice();
    const validationTestResults = validationTest(currentInputFields);
    const { passesTests } = validationTestResults;
    // We are clear to move forward with submitting
    if (passesTests) {
      const userInputs = currentInputFields.map(e => e.input);
      const username = this.props.location.state.email;
      const [code, new_password] = userInputs;
      // Page must have an email to load so verify the email is still intact
      if (!username) {
        const errorMessage = 'An error occured. Please restart password reset.'
        this.setState({ requestingAuth: false, resetButtonEnabled: false, resetError: true, resetErrorMessage:  errorMessage });
      }
      else {
        try {
          await Auth.forgotPasswordSubmit(
            username,
            code,
            new_password
          );
          this.setState({ 
            requestingAuth: false,
            resetSuccess: true
          });
        }
        catch(err) {
          if (err.code === 'ExpiredCodeException') {
            this.setState({
              sendToForgotPassword: true,
              requestingAuth: false,
              resetButtonEnabled: true,
              submitError: true,
              submitErrorMessage: err.message,
              resetSuccess: false
            });
          } else {
            this.setState({ 
              requestingAuth: false, 
              resetButtonEnabled: true, 
              submitError: true, 
              submitErrorMessage: err.message, 
              resetSuccess: false 
            });
          }  
        }
      }
    } 
    // Validation Test Fails
    else {
      this.setState({ requestingAuth: false })
    }
  }

  handleChange(index, userInput) {
    let resetButtonEnabled = false;
    let newInputFieldsState = this.state.inputFields.slice();
    let newInputFieldObject = newInputFieldsState[index];

    newInputFieldObject.input = userInput;
    newInputFieldObject.hasError = false;
    newInputFieldObject.errorMessage = '';

    // User has just typed (used to ensure we don't error without them actually typing info)
    if (userInput.length > 0) {
      newInputFieldsState[index].justTyped = true;
    } else {
      newInputFieldsState[index].justTyped = false;
    }

    // Check all fields and if they pass all tests, make button active
    const validationTestResults = validationTest(newInputFieldsState);
    const { passesTests } = validationTestResults;
    if (passesTests) {
      resetButtonEnabled = true
    }
    this.setState({ newInputFieldsState, resetButtonEnabled, resetErrorMessage: '', resetError: false });
  }

  handleInputActive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    newInputFieldsState[index].active = isActive;

    this.setState({ inputFields: newInputFieldsState });
  }

  handleInputInactive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    // Used to change the background color (when state is not active for the input field)
    newInputFieldsState[index].active = isActive;

    // If the user just finished typing and had some input
    if (newInputFieldsState[index].justTyped === true) {
      // Check that the field is valid, if not post an error
      const validateFieldResult = validateField(index, newInputFieldsState);
      const { pass, error } = validateFieldResult;
      if (pass !== true) {
        newInputFieldsState[index].hasError = true;
        newInputFieldsState[index].errorMessage = error;
      }
    }

    this.setState({ inputFields: newInputFieldsState });
  }

  handleKeyPress(index, event) {
    if (event.key === 'Enter' && this.state.resetButtonEnabled === true) {
      this.handleSubmit();
    }
  }

  handleButtonAnimation(event) {
    if (event.type === 'mousedown') {
      this.setState({ buttonAnimation: true });
    } else {
      this.setState({ buttonAnimation: false });
    }
  }

  handleAccountHelper(event) {
    this.setState({ helpLoggingIn: true })
  }

  render(){
    const { resetButtonEnabled, helpLoggingIn, sendToForgotPassword, resetSuccess } = this.state;
    const email = this.props.location.state.email;

    if (helpLoggingIn === true) {
      
    } 
    else if (!email){
      return (
        <Redirect to='/login' />
      );
    }
    else if (sendToForgotPassword) {
      return (
        <Redirect to='forgot-password' />
      );
    }
    else if (resetSuccess) {
      return (
        <Redirect to='/password-reset-success' />
      );
    }
    else {
      return (
        <AuthContainer>
          <Logo />
          <HeaderText>
            Reset Password
          </HeaderText>
          <ResetHeaderText>
            In order to reset your password, please use the <br /> confirmation code sent to your email.
          </ResetHeaderText>   
          {
            this.state.submitError && (
              <ErrorMessageHeader>
                { this.state.submitErrorMessage }
              </ErrorMessageHeader>
            )
          }
          {
            this.state.inputFields.map(el => (
              <FieldRow key={el.index}>
                <InputContainer key={el.index} active={el.active} index={el.index}>
                  <Input
                  key={el.index}
                  ref={(node) => this[`InputField${el.index}`] = node}
                  autoFocus={el.firstInput}
                  type={el.type}
                  index={el.index}
                  active={el.active}
                  placeholder={el.placeholder}
                  onChange={e => this.handleChange(el.index, e.target.value)}
                  onFocus={() => this.handleInputActive(el.index, true)}
                  onBlur={() => this.handleInputInactive(el.index, false)}
                  onKeyPress={(e) => this.handleKeyPress(el.index, e)}
                  />
                  <ErrorMessage hasError={el.hasError}>
                    {el.errorMessage}
                  </ErrorMessage>
                </InputContainer> 
              </FieldRow>
            ))
          }
          <ResetButton
            disabled={!resetButtonEnabled}
            onClick={e => this.handleSubmit(e)}
            onMouseUp={e => this.handleButtonAnimation(e)}
            onMouseDown={e => this.handleButtonAnimation(e)}
            >
            {
              !this.state.requestingAuth && `Reset Password`
            }
            {
              this.state.requestingAuth && (
                <FontAwesomeIcon icon={faCircleNotch} spin/>
              )
            }
          </ResetButton>
        </AuthContainer>
      );
    }
  }
}

export default withRouter(ResetPassword);

const ResetHeaderText = styled.p`
  font-size: 0.67rem;
  padding: 0;
  margin-top: 0rem;
  margin-bottom: 0.67rem;
  color: rgb(64,64,64);
`;

const FieldRow = styled.div`
  min-width: 19rem;
  max-width: 100%;
`;

const InputContainer = styled.div`
  padding-left: 0.08rem;
  padding-right: 0.08rem;
  ${({ active }) => active && `
  background: WhiteSmoke;
  `}
  border-bottom-style: solid;
  border-bottom-color: WhiteSmoke;
  border-bottom-width: 2px;
  width: 16.5rem;
  display: inline-block;
`;

const Input = styled.input`
  font-size: 0.67rem;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding: 0.5rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  width: 15.5rem;
`;

const ResetButton = styled.button`
  margin-top: 1rem;
  color: #FFF;
  background-color: ${DARK_COLOR};
  border-color: ${DARK_COLOR};
  cursor: pointer;
  ${({ disabled }) => disabled && `
    background-color: LightGray;
    cursor: not-allowed;
  `};
  border-radius: .1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0.4rem 1rem;
  width: 16.5rem;
  font-size: 0.67rem;
  line-height: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus {
      outline: 0;
    }
`;

const ErrorMessage = styled.p`
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin: 0;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;

const ErrorMessageHeader = styled.p`
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin-bottom: 0.67rem;
  margin-top: 0rem;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;