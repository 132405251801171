import styled from 'styled-components';
import { NAVLINKCOLOR } from './customizer';

export const NavLinks = styled.ul({
  'list-style': 'none',
  'padding': '0.3rem',
  'margin': '0.1rem',
  li: {
    display: 'inline-block',
    padding: '0 0.67rem',
    'font-size': '0.67rem',
    'font-weight': '600'
  },
  'li a, li a:visited': {
    color: NAVLINKCOLOR,
    'text-decoration': 'none'
  },
  'li a:hover, li a: active': {
    'text-decoration': 'none',
    'text-underline-position': 'under',
    'text-decoration-color': NAVLINKCOLOR
  }
})