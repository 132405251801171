import React from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { ContainerFluid } from '../../scaffold/blocks';
import SideBarNavigation from './SideBarNavigation';
import { 
  BLUE_COLOR,
  SUCCESS_COLOR
} from '../../scaffold/customizer';

import { 
  ButtonBlue
} from '../../scaffold/buttons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart,
  faDolly,
  faWrench,
  faTimesCircle,
  faDrawPolygon,
  faMeteor,
  faStore,
  faCity,
  faTshirt,
  faMemory,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  faBitcoin,
  faYoutube,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

class CreateShop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName: 'Create Shop',
      authenticated: false,
      user: {},
      showBuildPage: false,
      selectedStoreType: 'The Store',

    }
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSidebarButtons = this.handleSidebarButtons.bind(this);
    this.handleBuildShopClicked = this.handleBuildShopClicked.bind(this);
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "#fafafa";

    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: false});
      console.log(user);
      this.setState({ authenticated: true,  user});
    }
    catch(err) {
      this.props.history.push('/login');
    }
  }

  handleBuildShopClicked(e) {
    this.setState({ showBuildPage: true });
  }

  handleCancelShopBuild(e) {
    this.setState({ showBuildPage: false });
  }

  async handleLogout(e) {
    try {
      await Auth.signOut();
      this.props.history.push('/login');
    }
    catch(err) {
      this.props.history.push('/login');
    }
  }

  handleSidebarButtons(path, event) {
    this.props.history.push({
      pathname: path
    });
  }

  render() {
    const { pageName, showBuildPage } = this.state;
    return (
      <ContainerFluid>
        <ContentContainer>
          <SideBarNavigation
            onSideBarButtonClick={this.handleSidebarButtons}
            onLogoutButtonClick={this.handleLogout}
            buttonActive={pageName}
          />
          <MainContainer>
            {
              !showBuildPage && (
                <InitialContainer>
                  <ButtonBlue onClick={e => this.handleBuildShopClicked(e)}>
                  <FontAwesomeIcon icon={faWrench}/>&nbsp;
                  Generate Template
                  </ButtonBlue>
                  <ShopFlexContainer>
                    <ShopCardEmpty>
                      <FontAwesomeIcon icon={faShoppingCart} color="Gainsboro" size={'3x'} fixedWidth/>
                      <span>&nbsp;</span>
                      <TemplateText>
                      &nbsp;&nbsp;Create your first shop
                      </TemplateText>
                    </ShopCardEmpty>
                  </ShopFlexContainer>
                </InitialContainer>
              )
            }
            {
              showBuildPage && (
                <BuildPageContainer>
                  <BuildPage>
                    <HeaderFlexContainer>
                      <TitleText>Customize Your Build</TitleText>&nbsp;
                      <CancelButton onClick={e => this.handleCancelShopBuild(e)}>
                        <FontAwesomeIcon icon={faTimesCircle} size={'lg'} color="Crimson" fixedWidth/>
                      </CancelButton>
                    </HeaderFlexContainer>
                    <SectionContainer>
                      <TitleSubtext>
                        Name of Template&nbsp;
                        <FontAwesomeIcon icon={faQuestionCircle} color={BLUE_COLOR} size={'1x'} fixedWidth/>
                      </TitleSubtext>
                      <Divider />
                        <Input
                          active
                          autoFocus
                          placeholder={"Name your template"}
                          />
                    </SectionContainer>
                    <SectionSpacer></SectionSpacer>
                    <SectionContainer>
                      <TitleSubtext>What kind of store would you like to start?</TitleSubtext>
                      <Divider />
                      <RegularText>
                        Each site is designed to optimize for a specific category.
                      </RegularText>
                      <SelectionContainer>
                        <CardContainer active height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faDolly} size={'2x'} fixedWidth/>
                            <CardHeaderText>The Store</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Most popular store
                              </li>
                              <li>
                                Regular upkeep costs
                              </li>
                              <li>
                                Fits most inventory sizes
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faMeteor} size={'2x'} fixedWidth/>
                            <CardHeaderText>Splash Page</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Used for single item stores
                              </li>
                              <li>
                                Low upkeep costs
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faDrawPolygon} size={'2x'} fixedWidth/>
                            <CardHeaderText>Prototype</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Great for testing the market
                              </li>
                              <li>
                                Low upkeep costs
                              </li>
                              <li>
                                Great for selling few items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faStore} size={'2x'} fixedWidth/>
                            <CardHeaderText>Mini Shop</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Great for the starter store
                              </li>
                              <li>
                                Low upkeep costs
                              </li>
                              <li>
                                Fair amount of items for sale
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faBitcoin} size={'2x'} fixedWidth/>
                            <CardHeaderText>Crypto Store</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Great for enthusiasts
                              </li>
                              <li>
                                Low to medium upkeep
                              </li>
                              <li>
                                Support for fair amount of items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faTshirt} size={'2x'} fixedWidth/>
                            <CardHeaderText>Clothing Brand</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Great for most clothing shops
                              </li>
                              <li>
                                Low to medium upkeep
                              </li>
                              <li>
                                Support for fair amount of items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faYoutube} size={'2x'} fixedWidth/>
                            <CardHeaderText>Youtube Friendly</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Easily sell fan merchandise
                              </li>
                              <li>
                                Low to medium upkeep
                              </li>
                              <li>
                                Support for fair amount of items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faInstagram} size={'2x'} fixedWidth/>
                            <CardHeaderText>Instagram Friendly</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Connect followers with your stunning images
                              </li>
                              <li>
                                Low to medium upkeep
                              </li>
                              <li>
                                Support for fair amount of items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faMemory} size={'2x'} fixedWidth/>
                            <CardHeaderText>Technology Store</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                General tech store
                              </li>
                              <li>
                                Low to medium upkeep
                              </li>
                              <li>
                                Support for fair amount of items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'13rem'} width={'10rem'}>
                          <CardHeader height={'5.5rem'}>
                            <FontAwesomeIcon icon={faCity} size={'2x'} fixedWidth/>
                            <CardHeaderText>Mega Store</CardHeaderText>
                          </CardHeader>
                          <CardContent height={'6.5rem'}>
                            <ul>
                              <li>
                                Great for large scale stores
                              </li>
                              <li>
                                Medium/High upkeep costs
                              </li>
                              <li>
                                Support huge varieties of items
                              </li>
                              <li>
                                Free SSL, DDoS mitigation
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                      </SelectionContainer>
                    </SectionContainer>
                    <SectionSpacer></SectionSpacer>
                    <SectionContainer>
                      <TitleSubtext>
                        Select a Package&nbsp;
                        <FontAwesomeIcon icon={faQuestionCircle} color={BLUE_COLOR} size={'1x'} fixedWidth/>
                      </TitleSubtext>
                      <Divider />
                      <RegularText>
                        Choose the best package within your budget. <strong>Making templates is free, but charges start after you build your shop.</strong>
                      </RegularText>
                      <SelectionContainer>
                        <CardContainer height={'9rem'} width={'8rem'} active >
                          <CardHeader height={'4rem'}>
                            <DollarText>
                              <span>$</span>
                              9
                              <span>/mo</span>
                            </DollarText>
                            <PriceSubText>
                                Lite
                            </PriceSubText>
                          </CardHeader>
                          <CardContent height={'5rem'}>
                            <ul>
                             <li>

                             </li>
                              <li>
                                
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'9rem'} width={'8rem'} >
                          <CardHeader height={'4rem'}>
                            <DollarText>
                              <span>$</span>
                              16
                              <span>/mo</span>
                            </DollarText>
                            <PriceSubText>
                                Standard
                            </PriceSubText>
                          </CardHeader>
                          <CardContent height={'5rem'}>
                            <ul>
                              <li>
                                2m requests per month
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'9rem'} width={'8rem'}  >
                          <CardHeader height={'4rem'}>
                            <DollarText>
                              <span>$</span>
                              22
                              <span>/mo</span>
                            </DollarText>
                            <PriceSubText>
                              Professional
                            </PriceSubText>
                          </CardHeader>
                          <CardContent height={'5rem'}>
                            <ul>
                              <li>
                                3m requests per month
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                        <CardContainer height={'9rem'} width={'8rem'}  >
                          <CardHeader height={'4rem'}>
                            <DollarText>
                              <span>$</span>
                              49
                              <span>/mo</span>
                            </DollarText>
                            <PriceSubText>
                              Enterprise
                            </PriceSubText>
                          </CardHeader>
                          <CardContent height={'5rem'}>
                            <ul>
                              <li>
                                3m requests per month
                              </li>
                            </ul>
                          </CardContent>
                        </CardContainer>
                      </SelectionContainer>
                    </SectionContainer>
                    <SectionSpacer></SectionSpacer>
                   
                    <CreateButtonContainer>
                      <CreateTemplateButton>
                        Create Template
                      </CreateTemplateButton>
                    </CreateButtonContainer>
                    
                    <SectionSpacer></SectionSpacer>
                    <SectionSpacer></SectionSpacer>
                  </BuildPage>
                </BuildPageContainer>
              )
            }
          </MainContainer>
        </ContentContainer>
      </ContainerFluid>
    );
  }
}

export default CreateShop;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
`;

const MainContainer = styled.div`
  background: #fafafa;
  width: 100%;
  text-align: left;
`;

const InitialContainer = styled.div`
  padding: 2vw;
`;

const ShopFlexContainer = styled.div`
  margin-top: 2vh;
  display: flex;
`;

const ShopCardEmpty = styled.div`
  height: 10rem;
  width: 15rem;
  min-height: 7.5rem;
  min-width: 10rem;
  border: dashed 0.25rem Gainsboro;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TemplateText = styled.span`
  font-size: 0.75rem;
  color: Silver;
  font-weight: 600;
`;
const BuildPageContainer = styled.div`
  padding-left: 0.1rem;
`;

const BuildPage = styled.div`
  width: 100%;
  background: white;
`;

const HeaderFlexContainer = styled.div`
  display: flex;
  align-items: left;
  padding: 2vw;
`;

const TitleText = styled.h2`
  font-weight: 600;
  margin: 0;
`;

const TitleSubtext = styled.h5`
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

const CancelButton = styled.button`
  align-self: center;
  font-size: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
  padding-left: 0;
  cursor: pointer;
  border: none;
  background: none;
  &:focus{
    outline: none;
  }
`;

const SectionContainer = styled.div`
  margin: 0;
  padding: 0 2vw;
`;

const Input = styled.input`
  font-size: 0.9rem;
  font-weight: 600;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding: 0.75rem 1rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  width: 15.5rem;
`;

const SelectionContainer = styled.div`
  padding: 1vw 0;
  display: flex;
  flex-wrap: wrap;
`;

const CardContainer = styled.div`
  border: 1px solid Gainsboro;
  border-radius: 0.1rem;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: 0.25rem;
  color: Gray;
  background-color: White;
  &:hover {
    border-color: DarkGray;
  }
  &:active{
    color: ${BLUE_COLOR};
    border-color: ${BLUE_COLOR};
  }
  &:active > div:first-child {
    border-color: ${BLUE_COLOR};
    background-color: rgb(22, 82, 240, 0.03);
    filter: brightness(110%);
  }
  &:hover > div:first-child {
    background-color: rgb(245,245,245,0.75);
  }
  ${({ active }) => active && `
    color: ${BLUE_COLOR};
    border-color: ${BLUE_COLOR};
    & > div:first-child {
      background-color: rgb(22, 82, 240, 0.03);
      border-color: ${BLUE_COLOR};
    }
    &:hover > div:first-child {
      background-color: rgb(22, 82, 240, 0.03);
    }
    &:hover {
      border-color: ${BLUE_COLOR};
    }
  `};
`;

const CardHeader = styled.div`
  height: ${props => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid Gainsboro;
`;

const CardHeaderText = styled.span`
  font-size: 0.72rem;
  font-weight: 500;
  margin: 0.25rem;
  user-select: none;
`;

const CardContent = styled.div`
  height: ${props => props.height};
  display: flex;
  user-select: none;
  ul {
    list-style-position: outside;
    padding: 0.5rem;
    margin-top: 0;
    margin-left: 0.5rem;
  }
  ul li {
    margin: 0.25rem;
    font-size: 0.64rem;
  }
`;

const Divider = styled.div`
  margin-top: .5vw;
  margin-bottom: 1vw;
  height: 4px;
  width: 90%;
  background-color: WhiteSmoke;
`;

const SectionSpacer = styled.div`
  height: 5vh;
`;

const RegularText = styled.span`
  font-size: 0.67rem;
`;

const DollarText = styled.span`
  user-select: none;
  font-size: 1.5rem;
  font-weight: 600;
  & > span:first-child {
    vertical-align:middle;
  }
  span {
    font-size: 0.67rem;
    font-weight: 400;
  }
`;

const PriceSubText = styled.span`
  user-select: none;
  font-size: 0.6rem;
  font-weight: 400;
`;

const CreateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CreateTemplateButton = styled.button`
  font-size: 0.76rem;
  height: 2rem;
  width: 30vw;
  color: #FFF;
  background-color: ${SUCCESS_COLOR};
  border-color: ${SUCCESS_COLOR};
  border-radius: 0.1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:focus{
    outline: 0;
    box-shadow: 0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5) !important;
    filter: brightness(0.95);
  }
`;