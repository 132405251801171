import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';

function NewsDisplayTemplate() {
  return (
    <WindowContainer>
      <WindowHeader>
        <WindowHeaderText>
          News &amp; Updates
        </WindowHeaderText>
        <WindowHeaderText>
          <FontAwesomeIcon icon={faCaretDown} fixedWidth/>
        </WindowHeaderText>
      </WindowHeader>
      <WindowContent>

      </WindowContent>
    </WindowContainer>
  );
}

export default NewsDisplayTemplate;

const WindowContainer = styled.div`
  flex: 0 0 28vw;
  min-width: 13rem;
  min-height: 20vh;
  margin: 1vw;
  align-self: center;
  box-shadow: 
  0 1px 2px rgba(0,0,0,0.02), 
  0 2px 4px rgba(0,0,0,0.02), 
  0 4px 8px rgba(0,0,0,0.02), 
  0 8px 16px rgba(0,0,0,0.02),
  0 16px 32px rgba(0,0,0,0.02), 
  0 32px 64px rgba(0,0,0,0.02);
`;

const WindowHeader = styled.div`
  border-bottom: 1px solid Gainsboro;
  text-align: left;
  padding: 0.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WindowHeaderText = styled.span`
  font-size: 0.67rem;
  font-weight: 600;
  padding-left: 1vw;
  margin: 0;
`;

const WindowContent = styled.div`
  height: 15vh;
  background-color: white;
  padding: 0.5rem;
`;


