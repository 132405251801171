import styled from 'styled-components';

/* 
All block combinations must add to 100!
Note: decimal blocks can be used for even greater customization.
*/

/* 20 Blocks for 100% */
export const Block5 = styled.div({
  width: '5%',
  float: 'left',
});

/* 12 Blocks ... */
export const Block8_33 = styled.div({
  width: '8.33%',
  float: 'left',
});

/*  8 Blocks ... */
export const Block12_5 = styled.div({
  width: '12.5%'
});

/* 6 Blocks ... */
export const Block16_66 = styled.div({
  width: '16.66%',
  float: 'left',
});

/* 5 Blocks ... */
export const Block20 = styled.div({
  width: '20%',
  float: 'left',
});

/* 4 Blocks ... */
export const Block25 = styled.div({
  width: '25%',
  float: 'left',
});

/* 3 Blocks ... */
export const Block33_33 = styled.div({
  width: '33.33%',
  float: 'left',
});

/* 2 Blocks ... */

export const Block50 = styled.div({
  width: '50%',
  float: 'left',
});

/* Special Blocks for Containing Blocks within */

export const Block75 = styled.div({
  width: '75%',
  float: 'left',
});

export const Block80 = styled.div({
  width: '80%',
  float: 'left',
});

export const Block90 = styled.div({
  width: '90%',
  float: 'left',
});

export const Block100 = styled.div({
  width: '100%',
  float: 'left',
});

export const ContainerFluid = styled.div({
  width: '100%',
  float: 'right',
});












