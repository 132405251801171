
import React from 'react';
import { Link } from 'react-router-dom'

function Logo(props){
  const url = props.url;
  return (
    <Link to={url}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="34px" height="33px" viewBox="0 0 33 33" enableBackground="new 0 0 33 33">
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M36.5,32.75"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M1.667,29.396"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="2.034" y1="26.701" x2="2.034" y2="7.701"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="32.033" y1="26.701" x2="32.033" y2="7.701"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="17.034" y1="26.701" x2="17.034" y2="6.701"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="25.033" y1="18.701" x2="25.033" y2="26.701"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="18.788" y1="10.515" x2="18.788" y2="10.515"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="9.034" y1="18.701" x2="9.034" y2="26.701"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="3.534" y1="6.201" x2="30.533" y2="6.201"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="10.534" y1="17.201" x2="23.533" y2="17.201"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="30.67" y1="3.604" x2="30.67" y2="3.604"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="3.534" y1="28.201" x2="30.533" y2="28.201"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M2.034,7.701c0-0.829,0.671-1.5,1.5-1.5"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M32.033,7.701c0-0.829-0.671-1.5-1.5-1.5"/>
          <line fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" x1="11.534" y1="17.201" x2="11.534" y2="17.201"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M9.034,18.701c0-0.829,0.671-1.5,1.5-1.5"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M25.033,18.701c0-0.829-0.671-1.5-1.5-1.5"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M2.034,26.701c0,0.828,0.672,1.5,1.5,1.5"/>
          <path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M32.033,26.701c0,0.829-0.671,1.5-1.5,1.5"/>
      </svg>
    </Link>
  );
};

export default Logo;