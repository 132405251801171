import React from 'react';
import { ContainerFluid } from '../scaffold/blocks'
import { ButtonBlue } from '../scaffold/buttons';
import FrontNavBar from './FrontNavBar';
import styled from 'styled-components';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    this.handleGetStarted = this.handleGetStarted.bind(this);
  }
  handleGetStarted(e) {
    setTimeout(() => {
      this.props.history.push('/create-account');
    }, 100);
  }
  render() {
    return (
      <ContainerFluid>
        <FrontNavBar></FrontNavBar>
        <HeroContainer>
          <HeroTextContainer>
            <HeroText>
              Create Your Own Store and<br></br>  Start Selling Today
            </HeroText>
            
          </HeroTextContainer>
          <HeroSubTextContainer>
            <HeroSubText>
              Get a professional store front up in minutes with<br />
            </HeroSubText>
          </HeroSubTextContainer>
          <HeroButtonContainer>
            <ButtonBlue onClick={e => this.handleGetStarted(e)}>
              Get Started
            </ButtonBlue>
          </HeroButtonContainer>
        </HeroContainer>
      </ContainerFluid> 
    );
  }
}

export default Home;

const HeroContainer = styled.div`
  border-top: 1px solid rgb(224,224,224);
  background-color: rgba(250,250,250,1);
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: top;
  flex-direction: column;
  text-align: center;
  // 'background-image': 'url("/images/heroimage.jpg")',
  // 'background-repeat': 'no-repeat',
  // 'background-size': 'cover',
  // 'background-position': 'top',
`;

const HeroTextContainer = styled.div`
  margin-top: 15vh;
`;

const HeroSubTextContainer = styled.div`
  margin-top: 2vh;
`;

const HeroSubText = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

const HeroText = styled.span`
  padding: 1rem;
  color: #212121;
  font-weight: 700;
  font-size: 2.1rem;
`;

const HeroButtonContainer = styled.div`
  margin-top: 2vh;
`;

