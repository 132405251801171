// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f05f7251-749e-4e4c-9b05-4fcdb5389d21",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tpRPNHqte",
    "aws_user_pools_web_client_id": "7r4fm2e32qvn5loh289h0rn6ov",
    "oauth": {},
    "aws_content_delivery_bucket": "megamart-megamart",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d20mb1l8i7tfv8.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://h2o9jgncw6.execute-api.us-west-2.amazonaws.com/megamart",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
