import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { DARK_COLOR } from '../scaffold/customizer';
import Logo from './Logo';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { validateField, validationTest } from '../validation/createAccountTester';

class CreateAccount extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inputFields: [
        { name: 'firstName', type:'text', placeholder: 'First Name', input: '', active: false, index: 0, justTyped: false, hasError: false, errorMessage:'', firstInput: true},
        { name: 'lastName', type:'text', placeholder: 'Last Name', input: '', active: false, index: 1, justTyped: false, hasError: false, errorMessage:'', firstInput: false},
        { name: 'emailAddress', type:'text', placeholder: 'Email Address', input: '', active: false, index: 2, justTyped: false, hasError: false, errorMessage:'', firstInput: false},
        { name: 'password', type:'password', placeholder: 'Password', input: '', active: false, index: 3, justTyped: false, hasError: false, errorMessage:'', firstInput: false },
        { name: 'confirmPassword', type:'password', placeholder: 'Confirm Password', input: '', active: false, index: 4, justTyped: false, hasError: false, errorMessage:'', firstInput: false }
      ],
      passesValidation: false,
      createButtonEnabled: false,
      createError: false,
      requestingAuth: false,
      buttonAnimation: false,
      submitErrorMessage: '',
      submitError: false,
      signUpSuccess: false,
      email: '',
      goToLogin: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputActive = this.handleInputActive.bind(this);
    this.handleInputInactive = this.handleInputInactive.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleButtonAnimation = this.handleButtonAnimation.bind(this);
    this.handleLoginButton = this.handleLoginButton.bind(this);
  }

  async handleSubmit(e){
    this.setState({ requestingAuth: true, createButtonEnabled: false });
    const currentInputFields = this.state.inputFields.slice();
    const validationTestResults = validationTest(currentInputFields);
    const { passesTests } = validationTestResults;
    // We are clear to move forward with submitting
    if (passesTests) {
      const userInputs = currentInputFields.map(e => e.input);
      const [firstName, lastName, emailAddress, password] = userInputs;
      try {
        const data = await Auth.signUp({
          username: emailAddress,
          password: password,
          attributes: {
            email: emailAddress,
            'custom:first_name': firstName,
            'custom:last_name': lastName
          }
        });
        const cognitoUser = data.user;

        this.setState({ 
          requestingAuth: false,
          signUpSuccess: true,
          email: cognitoUser.username
        });
      }
      catch(err) {
        console.log(err);
        this.setState({ 
          requestingAuth: false, 
          createButtonEnabled: true, 
          submitError: true, 
          submitErrorMessage: err.message, 
          signUpSuccess: false });
      }
    } 
    // Validation Test Fails
    else {
      this.setState({ requestingAuth: false })
    }
  }

  handleChange(index, userInput){
    let createButtonEnabled = false;
    let newInputFieldsState = this.state.inputFields.slice();
    let newInputFieldObject = newInputFieldsState[index];

    newInputFieldObject.input = userInput;
    newInputFieldObject.hasError = false;
    newInputFieldObject.errorMessage = '';

    // User has just typed (used to ensure we don't error without them actually typing info)
    if (userInput.length > 0) {
      newInputFieldsState[index].justTyped = true;
    } else {
      newInputFieldsState[index].justTyped = false;
    }
    // Check all fields and if they pass all tests, make button active
    const validationTestResults = validationTest(newInputFieldsState);
    const { passesTests } = validationTestResults;
    if (passesTests) {
      createButtonEnabled = true
    }
    this.setState({ newInputFieldsState, createButtonEnabled, submitError: false, submitErrorMessage: ''  });
  }

  handleInputActive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    newInputFieldsState[index].active = isActive;

    this.setState({ inputFields: newInputFieldsState });
  }

  handleInputInactive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    // Used to change the background color (when state is not active for the input field)
    newInputFieldsState[index].active = isActive;

    // If the user just finished typing and had some input
    if (newInputFieldsState[index].justTyped === true) {
      // Check that the field is valid, if not post an error
      const validateFieldResult = validateField(index, newInputFieldsState);
      const { pass, error } = validateFieldResult;
      if (pass !== true) {
        newInputFieldsState[index].hasError = true;
        newInputFieldsState[index].errorMessage = error;
      }
    }
    this.setState({ inputFields: newInputFieldsState });
  }

  handleKeyPress(index, event) {
    if (event.key === 'Enter' && this.state.createButtonEnabled === true) {
      this.handleSubmit();
    }
  }

  handleButtonAnimation(event) {
    if (event.type === 'mousedown') {
      this.setState({ buttonAnimation: true });
    } else {
      this.setState({ buttonAnimation: false });
    }
  }

  handleLoginButton(e) {
    this.setState({ goToLogin: true });
  }

  render(){
    const { createButtonEnabled, email, signUpSuccess, goToLogin } = this.state;

    if (signUpSuccess && email.length > 0) {
      return (
        <Redirect to={{
          pathname: '/verify-email',
          state: { email: email }
        }} />
      );
    }
    else if (goToLogin) {
      return (
        <Redirect to='/login' />
      );
    }
    else {
      return (
        <AuthContainer>
          <Logo url={'/'}/>
          <HeaderText>
            Create Account
          </HeaderText>
          {
            this.state.submitError && (
              <ErrorMessageHeader>
                { this.state.submitErrorMessage }
              </ErrorMessageHeader>
            )
          }
          <FormContainer>
            {
              this.state.inputFields.map(el => (
                <FieldRow key={el.index}>
                  <InputContainer key={el.index} active={el.active} index={el.index}>
                    <Input
                    key={el.index}
                    ref={(node) => this[`InputField${el.index}`] = node}
                    autoFocus={el.firstInput}
                    type={el.type}
                    index={el.index}
                    active={el.active}
                    placeholder={el.placeholder}
                    onChange={e => this.handleChange(el.index, e.target.value)}
                    onFocus={() => this.handleInputActive(el.index, true)}
                    onBlur={() => this.handleInputInactive(el.index, false)}
                    onKeyPress={(e) => this.handleKeyPress(el.index, e)}
                    />
                    <ErrorMessage hasError={el.hasError}>
                      {el.errorMessage}
                    </ErrorMessage>
                  </InputContainer> 
                </FieldRow>
              ))
            }
          </FormContainer>
          <CreateAccountButton
            disabled={!createButtonEnabled}
            onClick={e => this.handleSubmit(e)}
            onMouseUp={e => this.handleButtonAnimation(e)}
            onMouseDown={e => this.handleButtonAnimation(e)}
            >
            {
              !this.state.requestingAuth && `Create Account`
            }
            {
              this.state.requestingAuth && (
                <FontAwesomeIcon icon={faCircleNotch} spin/>
              )
            }
          </CreateAccountButton>
          <TermsText>
            By creating an account, you agree to our Terms of Service <br /> and have read and understood the Privacy Policy.
          </TermsText>
          <OrSeperator>
            <span>or</span>
          </OrSeperator>
          <LoginText>
            Already have an account?&nbsp;<LoginButton onClick={e => this.handleLoginButton(e)}>Log In</LoginButton>
          </LoginText>
        </AuthContainer>
      );
    }
    
  }
}

export default CreateAccount;

const FormContainer = styled.div`
  padding: 0;
  margin-top: 0.5rem;
`;

const TermsText = styled.span`
  font-size: 0.55rem;
  padding: 0;
  margin: 0.67rem;
  color: Gray;
`;

const OrSeperator = styled.p`
  width: 75%; 
  text-align: center; 
  border-bottom: 1px solid Gainsboro;
  line-height: 0.1em;
  margin: 0.3rem 0.7rem; 
  span {
    color: Gainsboro;
    font-size: 0.55rem;
    background:#fff; 
    padding:0 10px; 
  }
`;

const LoginText = styled.p`
  font-size: 0.6rem;
  padding: 0;
  margin: 0.67rem;
  color: Gray;
`;

const LoginButton = styled.button`
  font-size: 0.6rem;
  border: none;
  background: white;
  padding: 0;
  margin-left: 0.1rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const FieldRow = styled.div`
  min-width: 19rem;
  max-width: 100%;
`;

const InputContainer = styled.div`
  padding-left: 0.08rem;
  padding-right: 0.08rem;
  ${({ active }) => active && `
  background: WhiteSmoke;
  `}
  border-bottom-style: solid;
  border-bottom-color: WhiteSmoke;
  border-bottom-width: 2px;
  width: 16.5rem;
  display: inline-block;
`;

const Input = styled.input`
  font-size: 0.67rem;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding: 0.5rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  width: 15.5rem;
`;

const CreateAccountButton = styled.button`
  margin-top: 1rem;
  color: #FFF;
  background-color: ${DARK_COLOR};
  border-color: ${DARK_COLOR};
  cursor: pointer;
  ${({ disabled }) => disabled && `
    background-color: LightGray;
    cursor: not-allowed;
  `};
  border-radius: .1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0.4rem 1rem;
  width: 16.5rem;
  font-size: 0.67rem;
  line-height: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus {
      outline: 0;
    }
`;

const ErrorMessage = styled.p`
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin: 0;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;

const ErrorMessageHeader = styled.p`
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin-bottom: 0.67rem;
  margin-top: 0rem;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;