import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { DARK_COLOR } from '../scaffold/customizer';
import Logo from './Logo';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { validate_email } from '../validation/validate';

class ForgotPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inputFields: [
        { name: 'emailAddress', type:'text', placeholder: 'Email Address', input: '', active: false, index: 0, justTyped: false, hasError: false, errorMessage:'', firstInput: true}
      ],
      passesValidation: false,
      continueButtonEnabled: false,
      requestingAuth: false,
      buttonAnimation: false,
      helpLoggingIn: false,
      codeSent: false,
      email: ''
    }
  }

  async handleSubmit(e){
    // Used for the the spinning wheel animation in button
    this.setState({ requestingAuth: true });

    const currentInputFields = this.state.inputFields.slice();
    const [emailField] = currentInputFields;
    const username = emailField.input;

    const validationResults = validate_email(username);
    const { pass, error } = validationResults;
    if (pass === true) {
      try {
        const user = await Auth.forgotPassword(username);
        if (user) {
          this.setState({ codeSent: true, email: username, requestingAuth: false, continueButtonEnabled: false })
        }
      }
      catch(err) {
        this.setState({ requestingAuth: false });
        const newInputFields = this.state.inputFields.slice();
        const [newEmailObject] = newInputFields;
        const errorMessage = 'A user with that email does not exist.'
        newEmailObject.hasError = true;
        newEmailObject.errorMessage = errorMessage;
        this.setState({ inputFields: newInputFields, codeSent: false, email: '', requestingAuth: false, continueButtonEnabled: false });
      }
    } 
    else {
      emailField.hasError = true;
      emailField.errorMessage = error;
      this.setState({ inputFields: currentInputFields, passesValidation: false, requestingAuth: false, buttonAnimation: false })
    }
  }

  handleChange(index, userInput){
    let continueButtonEnabled = false;
    let newInputFieldsState = this.state.inputFields.slice();

    //Clear old error messages
    newInputFieldsState[index].input = userInput;
    newInputFieldsState[index].hasError = false;
    newInputFieldsState[index].errorMessage = '';

    // If the email field is not empty, active button
    if (newInputFieldsState[0].input.length > 0) {
      continueButtonEnabled = true;
    } else {
      continueButtonEnabled = false;
    }
    this.setState({ newInputFieldsState, continueButtonEnabled, loginErrorMessage: '', loginError: false, });
  }

  handleInputActive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    newInputFieldsState[index].active = isActive;
    this.setState({ inputFields: newInputFieldsState });
  }

  handleInputInactive(index, isActive) {
    let newInputFieldsState = this.state.inputFields.slice();
    // Used to change the background color (when state is not active for the input field)
    newInputFieldsState[index].active = isActive;
    this.setState({ inputFields: newInputFieldsState });
  }

  handleKeyPress(index, event) {
    if (event.key === 'Enter' && this.state.continueButtonEnabled === true) {
      this.handleSubmit();
    }
  }

  handleButtonAnimation(event) {
    if (event.type === 'mousedown') {
      this.setState({ buttonAnimation: true });
    } else {
      this.setState({ buttonAnimation: false });
    }
  }

  handleAccountHelper(event) {
    this.setState({ helpLoggingIn: true })
  }

  render(){
    const { continueButtonEnabled, helpLoggingIn, email, codeSent } = this.state;

    if (helpLoggingIn === true) {
      return (
        <Redirect to='/account-recovery'/>
      );
    } else if (codeSent && email.length > 0) {
      return (
        <Redirect to={{
          pathname: '/reset-password',
          state: { email: email }
        }} />
      )
    } else {
      return (
        <AuthContainer>
          <Logo />
          
          <HeaderText>
            Forgot Password
          </HeaderText>
          <Text>
            Enter the email address associated with <br/> your account to reset your password.
          </Text>
          {
            this.state.inputFields.map(el => (
              <FieldRow key={el.index}>
                <InputContainer key={el.index} active={el.active} index={el.index}>
                  <Input
                  key={el.index}
                  ref={(node) => this[`InputField${el.index}`] = node}
                  autoFocus={el.firstInput}
                  type={el.type}
                  index={el.index}
                  active={el.active}
                  placeholder={el.placeholder}
                  onChange={e => this.handleChange(el.index, e.target.value)}
                  onFocus={() => this.handleInputActive(el.index, true)}
                  onBlur={() => this.handleInputInactive(el.index, false)}
                  onKeyPress={(e) => this.handleKeyPress(el.index, e)}
                  />
                  <ErrorMessage hasError={el.hasError}>
                    {el.errorMessage}
                  </ErrorMessage>
                </InputContainer> 
              </FieldRow>
            ))
          }
          <ContinueButton
            disabled={!continueButtonEnabled}
            onClick={e => this.handleSubmit(e)}
            onMouseUp={e => this.handleButtonAnimation(e)}
            onMouseDown={e => this.handleButtonAnimation(e)}
            >
            {
              !this.state.requestingAuth && `Continue`
            }
            {
              this.state.requestingAuth && (
                <FontAwesomeIcon icon={faCircleNotch} spin/>
              )
            }
          </ContinueButton>
          <AccountHelperText onClick={e => this.handleAccountHelper(e)}>
            Still having an issue?
          </AccountHelperText>
        </AuthContainer>
      );
    }
    
  }
}

export default ForgotPassword;

const Text = styled.p`
  font-size: 0.67rem;
  margin: 0rem 0.5rem 1rem 0rem;
  padding: 0rem;
`;

const AccountHelperText = styled.button`
  margin-top: 0.8rem;
  font-size: 0.6rem;
  border: none;
  background: white;
  padding: 0;
  margin-left: 0.1rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const FieldRow = styled.div`
  min-width: 19rem;
  max-width: 100%;
`;

const InputContainer = styled.div`
  padding-left: 0.08rem;
  padding-right: 0.08rem;
  ${({ active }) => active && `
  background: WhiteSmoke;
  `}
  border-bottom-style: solid;
  border-bottom-color: WhiteSmoke;
  border-bottom-width: 2px;
  width: 16.5rem;
  display: inline-block;
`;

const Input = styled.input`
  font-size: 0.67rem;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding: 0.5rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  width: 15.5rem;
`;

const ContinueButton = styled.button`
  margin-top: 1rem;
  color: #FFF;
  background-color: ${DARK_COLOR};
  border-color: ${DARK_COLOR};
  cursor: pointer;
  ${({ disabled }) => disabled && `
    background-color: LightGray;
    cursor: not-allowed;
  `};
  border-radius: .1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0.4rem 1rem;
  width: 16.5rem;
  font-size: 0.67rem;
  line-height: 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus {
      outline: 0;
    }
`;

const ErrorMessage = styled.p`
  color: crimson;
  text-align: left;
  font-size: 0.6rem;
  margin: 0;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;