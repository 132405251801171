import React from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { ContainerFluid } from '../../scaffold/blocks';
import NewsDisplayTemplate from './NewsDisplayTemplate';

import {
  ExampleChart,
  ExampleChart2,
  ExampleChart3,
  ExampleChart4
} from './DashboardHeaderWindows';

import SideBarNavigation from './SideBarNavigation';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName: 'Dashboard',
      authenticated: false,
      user: {}
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSidebarButtons = this.handleSidebarButtons.bind(this);
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "#fafafa";

    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: false});
      this.setState({ authenticated: true,  user});
    } catch(err) {
      this.props.history.push('/login');
    }
  }

  async handleLogout(e) {
    try {
      await Auth.signOut();
      this.props.history.push('/login');
    }
    catch(err) {
      this.props.history.push('/login');
    }
  }

  handleSidebarButtons(path, event) {
    this.props.history.push(path)
  }

  render() {

    const { pageName } = this.state;
    return (
      <ContainerFluid>
        <ContentContainer>
          <SideBarNavigation
            onSideBarButtonClick={this.handleSidebarButtons}
            onLogoutButtonClick={this.handleLogout}
            buttonActive={pageName}
          />
          <MainContainer>
            <ContentLeftContainer>
              <NewsDisplayTemplate></NewsDisplayTemplate>
            </ContentLeftContainer>
            <ContentRightContainer>
              <SmallContainer>
                <AlignGraph>
                  <ExampleChart />
                </AlignGraph> 
              </SmallContainer>
              <SmallContainer>
                <AlignGraph>
                  <ExampleChart2 />
                </AlignGraph> 
              </SmallContainer>
              <SmallContainer>
                <AlignGraph>
                  <ExampleChart3 />
                </AlignGraph> 
              </SmallContainer>
              <SmallContainer>
                <AlignGraph>
                  <ExampleChart4 />
                </AlignGraph> 
              </SmallContainer>
            </ContentRightContainer>
          </MainContainer>
        </ContentContainer>   
      </ContainerFluid>
    );
  }
}

export default Dashboard;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
`;

const MainContainer = styled.div`
  background: #fafafa;
  left: calc(100vw - (100vw - 11rem));
  width: calc(100vw - 11.1rem);
  display: flex;
  flex-wrap: wrap;
  overlfow: hidden;
`;

const ContentLeftContainer = styled.div`
  flex: 0 0 50%;
  min-width: 10.76rem;
`;

const ContentRightContainer = styled.div`
  flex: 0 0 50%;
  min-width: 10.76rem;
  display: flex;
  flex-wrap: wrap;
`;

const SmallContainer = styled.div`
  background-color: #fff;
  flex: 0 1 18vw;
  min-width: 11rem;
  min-height: 9rem;
  flex-shrink: 0;
  margin: 1vw;
  box-shadow: 
  0 1px 2px rgba(0,0,0,0.02), 
  0 2px 4px rgba(0,0,0,0.02), 
  0 4px 8px rgba(0,0,0,0.02), 
  0 8px 16px rgba(0,0,0,0.02),
  0 16px 32px rgba(0,0,0,0.02), 
  0 32px 64px rgba(0,0,0,0.02);
  display: flex;
  justify-content: center;
`;

const AlignGraph = styled.div`
  margin: 1rem;
`;