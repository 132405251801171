import React from 'react';
import { ContainerFluid } from '../scaffold/blocks'
import FrontNavBar from './FrontNavBar';

function About() {
  return (
    <ContainerFluid>
      <FrontNavBar>

      </FrontNavBar>
      <h2>About</h2>
    </ContainerFluid>
  );
}

export default About;

