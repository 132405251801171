import styled from 'styled-components';
import { NAVBARHEIGHT } from './customizer';

const ADJUSTEDPADDING = `${NAVBARHEIGHT.replace(/rem/g,'') - 2}rem`;

export const NavigationBar = styled.nav({
  display: 'flex',
  'justify-content': 'flex-end',
  padding: `${ADJUSTEDPADDING} 5vw`,
  'align-items': 'center',
});

export const NavigationJustify = styled.nav({
  display: 'flex',
  'justify-content': 'flex-start',
  padding: '0.25rem 5vw',
  'align-items': 'center'
});

export const NavigationJustifyCenter = styled.nav({
  display: 'flex',
  'justify-content': 'center',
  padding: '0.25rem 5vw',
  'align-items': 'center'
});

/* Some might prefer to be more explicit */
export const NavigationJustifyFlexStart = styled.nav({
  display: 'flex',
  'justify-content': 'flex-start',
  padding: '0.25rem 5vw',
  'align-items': 'center'
});

export const NavigationJustifyFlexEnd = styled.nav({
  display: 'flex',
  'justify-content': 'flex-end',
  padding: '0.25rem 5vw',
  'align-items': 'center'
});

export const NavigationJustifySpaceAround = styled.nav({
  display: 'flex',
  'justify-content': 'space-around',
  padding: '0.25rem 5vw',
  'align-items': 'center'
});

export const NavigationJustifySpaceBetween = styled.nav({
  display: 'flex',
  'justify-content': 'space-between',
  padding: '0.5rem 5vw',
  'align-items': 'center'
});

