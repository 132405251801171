import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { AuthContainer } from '../scaffold/authentication';

class VerificationSuccess extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      finishedCountdown: false
    }
  }

  componentDidMount(){
    this.setTimer = setInterval(
      () => this.handleCountdown(),
      3500
    );
  }

  componentWillUnmount(){
    clearInterval(this.setTimer);
  }
  
  handleCountdown(){
    this.setState({finishedCountdown: true})
  }

  render(){
    const { finishedCountdown } = this.state;
    if (finishedCountdown) {
      return (
        <Redirect to='/login' />
      );
    } else {
      return (
        <AuthContainer>
          <h5>Password Reset Successful</h5>
        </AuthContainer>
      );
    }
  }
}


export default withRouter(VerificationSuccess);