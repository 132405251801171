import React from 'react';
import styled from 'styled-components';
import { DARK_COLOR } from '../scaffold/customizer';
import { AuthContainer, HeaderText } from '../scaffold/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import Logo from './Logo';

class MultiFactorVerification extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      active: true
    }
    this.handleInputActive = this.handleInputActive.bind(this);
    this.handleInputInactive = this.handleInputInactive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  handleGoBack(e) {
    this.props.onGoBackClick(e);
  }

  handleSubmit(e) {
    this.props.onSubmitClick(e);
  }

  handleChange(e) {
    this.props.onChange(e);
  }

  handleInputActive(isActive) {
    this.setState({ active: isActive });
  }

  handleInputInactive(isActive) {
    this.setState({ active: isActive });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  render(){
    const {
      errorStatus,
      errorMessage,
      buttonLoading
    } = this.props;

    const active = this.state.active;

    return (
      <AuthContainer>
        <Logo />
        <HeaderText>
          Verify Authenticator
        </HeaderText>
        <ErrorMessage hasError={errorStatus}>
          {errorMessage}
        </ErrorMessage>
        <HeaderMessage>
          Enter the one-time password generated from your authentication app.
        </HeaderMessage>
        <FieldRow>
          <FontAwesomeIcon icon={faMobileAlt} size='2x'/>
          <Input
            autoFocus
            ref={(node) => this['InputField0'] = node}
            active={active}
            onFocus={() => this.handleInputActive(true)}
            onBlur={() => this.handleInputInactive(false)}
            onChange={(e) => this.handleChange(e)}
            onKeyPress={(e) => this.handleKeyPress(e)}
            maxLength='7'
          >
          </Input>
          <VerifyButton onClick={e => this.handleSubmit(e)}>
              {
                !buttonLoading && `Verify`
              }
              {
                buttonLoading && (
                  <FontAwesomeIcon icon={faCircleNotch} spin/>
                )
              }
            </VerifyButton>
        </FieldRow>
        <FooterMessage>
          All you need to do is log in one last time after this. <br /> Don't worry you're almost done. 
        </FooterMessage>
        <OrSeperator>
          <span>or</span>
        </OrSeperator>
        <GoBackButton onClick={e => this.handleGoBack(e)}>
          Rescan Code
        </GoBackButton>
      </AuthContainer>
    );
  }
}

export default MultiFactorVerification;

const HeaderMessage = styled.p`
  font-size: 0.67rem;
  text-align: center;
  padding: 0 2.5rem;
`;

const FooterMessage = styled.p`
  font-size: 0.6rem;
  text-align: center;
  padding: 0 2.5rem;
`;

const FieldRow = styled.div`
  min-width: 19rem;
  max-width: 100%;
  margin: 0.67rem 0;
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  margin: 0 0.25rem 0.25rem 0.35rem;
  padding-left: 0.5rem;
  font-size: 0.67rem;
  border: 1px solid Gainsboro;
  letter-spacing: 0.2rem;
  border-radius: 0.1rem;
  ${({ active }) => active && `
    background: WhiteSmoke;
    background-clip: padding-box;
  `}
  &:hover, &:focus {
    outline: none;
  }
  height: 1.79rem;
  width: 4.5rem;
`;

const ErrorMessage = styled.p`
  color: crimson;
  text-align: center;
  font-size: 0.6rem;
  margin: 0;
  padding: 0rem;
  ${({ hasError }) => hasError && `
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  `}
`;

const GoBackButton = styled.button`
  margin: 0.67rem;
  font-size: 0.6rem;
  font-weight: 600;
  background: Gainsboro;
  color: ${DARK_COLOR};
  cursor: pointer;
  padding: 0.5rem;
  width: 16.5rem;
  border: 0;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${DARK_COLOR};
    border-color: Gainsboro;
    background: rgb(224,224,224);
  }
`;

const OrSeperator = styled.p`
  width: 75%; 
  text-align: center; 
  border-bottom: 1px solid Gainsboro;
  line-height: 0.1em;
  margin: 0.3rem 0.7rem; 
  span {
    color: Gainsboro;
    font-size: 0.55rem;
    background:#fff; 
    padding:0 10px; 
  }
`;

const VerifyButton = styled.button`
  font-size: 0.6rem;
  font-weight: 600;
  background: rgb(48,48,48);
  color: #FFF;
  width: 4.25rem;
  height: 1.95rem;
  cursor: pointer;
  border-radius: 0.1rem;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #FFF;
    border-color: Gainsboro;
    background: rgb(56,56,56);
  }
`;